import React, { Component } from "react";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import classnames from "classnames";
import { connect } from "react-redux";
import { getFilm, editFilm, getTMDB, setTMDB } from "../../actions/filmActions";
import { Link } from "react-router-dom";
import moment from "moment";

import "../dashboard/Dashboard.css";

class EditFilmByUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.film.film.title,
      directors: [],
      genres: [],
      alt_titles: [],
      alt_titles_check: [],
      year: "",
      duration: "",
      duration_check: "",
      format: "",
      sidecar_type: "",
      sidecar_format: "",
      language: "",
      sidecar_by: "",
      commissioned_by: "",
      contact: "",
      certificate: "",
      certificate_check: "",
      frame_rate: "",
      quality_level: "",
      notes: "",
      approved: false,
      errors: {}
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getFilm(this.props.match.params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (nextProps.film.film) {
      const { film } = nextProps.film;
      this.setState({
        title: film.title,
        alt_titles: film.alt_titles,
        alt_titles_check: film.alt_titles_check,
        directors: film.directors,
        genres: film.genres,
        year: film.year,
        duration: film.duration,
        duration_check: film.duration_check,
        format: film.format,
        sidecar_type: film.sidecar_type,
        sidecar_format: film.sidecar_format,
        language: film.language,
        sidecar_by: film.sidecar_by,
        commissioned_by: film.commissioned_by,
        contact: film.contact,
        certificate: film.certificate,
        certificate_check: film.certificate_check,
        frame_rate: film.frame_rate,
        quality_level: film.quality_level,
        notes: film.notes,
        errors: nextProps.errors,
      });
    }
  }

  onTagChange = (e) => {
    const data = e.target.value.split(", ");
    this.setState({ [e.target.id]: data });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const newFilm = {
      title: this.state.title,
      alt_titles: this.state.alt_titles,
      alt_titles_check: this.state.alt_titles_check,
      directors: this.state.directors,
      genres: this.state.genres,
      year: this.state.year,
      duration: this.state.duration,
      duration_check: this.state.duration_check,
      format: this.state.format,
      sidecar_type: this.state.sidecar_type,
      sidecar_format: this.state.sidecar_format,
      language: this.state.language,
      sidecar_by: this.state.sidecar_by,
      commissioned_by: this.state.commissioned_by,
      contact: this.state.contact,
      certificate: this.state.certificate,
      certificate_check: this.state.certificate_check,
      frame_rate: this.state.frame_rate,
      quality_level: this.state.quality_level,
      notes: this.state.notes,
      approved: false,
    };

    this.props.editFilm(
      this.props.match.params.id,
      newFilm,
      this.props.history,
      false,
      true,
      ""
    );
  };


  render() {
    const { film } = this.props.film;
    const { errors } = this.state;

    return (
      <div className="middle-body edit-film">
        <Link to={`/account`}>
          ← Back to Profile (without saving)
        </Link>
        <br /> <br />
        <p>{this.state.flagged}</p>
        <h1>Edit Film</h1>
          <p style={{ marginRight: "20px" }}>
            Created at:{" "}
            {film.created_at ? moment(film.created_at).format("LLLL") : null}
          </p>
        <p>
          Last updated at:{" "}
          {film.updated_at ? moment(film.updated_at).format("LLLL") : null}
        </p>
        <p>Submitted by: {this.props.film.film.author}</p>
        <hr />
        <Form onSubmit={this.onSubmit}>
          <Form.Label>Notes (only visible to moderators/admins)</Form.Label>
          <Form.Control
            className={classnames("input-field", {
              invalid: errors.notes,
            })}
            placeholder="Add notes here..."
            as="textarea"
            value={this.state.notes}
            onChange={this.onChange}
            id="notes"
          />
          <hr />
          <Form.Label>Title</Form.Label>
          <span className="red-text">{errors.title}</span>
          <Form.Control
            className={classnames("input-field", {
              invalid: errors.title,
            })}
            placeholder="Title"
            value={this.state.title}
            onChange={this.onChange}
            id="title"
          />
          <Form.Label>Alternative Titles</Form.Label>
          <Form.Control
            className={classnames("input-field", {
              invalid: errors.alt_titles,
            })}
            placeholder="Alternative Titles"
            value={
              this.state.alt_titles ? this.state.alt_titles.join(", ") : ""
            }
            onChange={this.onTagChange}
            id="alt_titles"
            style={{ marginBottom: "3px" }}
          />
          {film.alt_titles_check &&
          film.alt_titles_check.length > 0 &&
          film.alt_titles.join(", ") === film.alt_titles_check.join(", ") ? (
            <p style={{ color: "#63c27e" }}>
              TMDB Alternative Titles: {film.alt_titles_check.join(", ")}
            </p>
          ) : film.alt_titles_check &&
            film.alt_titles_check.length > 0 &&
            film.alt_titles.join(", ") !== film.alt_titles_check.join(", ") ? (
            <p style={{ color: "#f52525" }}>
              TMDB Alternative Titles: {film.alt_titles_check.join(", ")}
            </p>
          ) : null}
          <Form.Label>Director/s</Form.Label>
          <Form.Control
            className={classnames("input-field", {
              invalid: errors.directors,
            })}
            placeholder="Directors"
            value={this.state.directors ? this.state.directors.join(", ") : ""}
            onChange={this.onTagChange}
            id="directors"
          />
          <Form.Label>Genre/s</Form.Label>
          <Form.Control
            className={classnames("input-field", {
              invalid: errors.genres,
            })}
            placeholder="Genre/s"
            value={this.state.genres ? this.state.genres.join(", ") : ""}
            onChange={this.onTagChange}
            id="genres"
          />
          <Form.Label>Year</Form.Label>
          <Form.Control
            className={classnames("input-field", {
              invalid: errors.year,
            })}
            placeholder="Year"
            value={this.state.year}
            onChange={this.onChange}
            id="year"
          />
          <Form.Label>Duration</Form.Label>
          <Form.Control
            className={classnames("input-field", {
              invalid: errors.duration,
            })}
            placeholder="Duration"
            value={this.state.duration}
            onChange={this.onChange}
            id="duration"
            style={{ marginBottom: "3px" }}
          />
          {film.duration_check && film.duration === film.duration_check ? (
            <p style={{ color: "#63c27e" }}>
              TMDB Duration: {film.duration_check}
            </p>
          ) : film.duration_check && film.duration !== film.duration_check ? (
            <p style={{ color: "#f52525" }}>
              TMDB Duration: {film.duration_check}
            </p>
          ) : null}

          <Row>
            <Col>
              <Form.Label>Category</Form.Label>
              <Form.Select
                className={classnames("input-field", {
                  invalid: errors.format,
                })}
                value={this.state.format}
                onChange={this.onChange}
                id="format"
              >
                <option>Category</option>
                <option value="DS">DS</option>
                <option value="AD">AD</option>
                <option value="Other">Other</option>
              </Form.Select>
            </Col>
            {this.state.format === "DS" ? (
              <React.Fragment>
                <Col>
                  <Form.Label>Sidecar Type</Form.Label>

                  <Form.Select
                    className={classnames("input-field", {
                      invalid: errors.sidecar_type,
                    })}
                    value={this.state.sidecar_type}
                    onChange={this.onChange}
                    id="sidecar_type"
                  >
                    <option>Subtitle Type</option>
                    <option value="DS">DS</option>
                    <option value="DS Only">DS Only</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label>Sidecar File Format</Form.Label>

                  <Form.Select
                    className={classnames("input-field", {
                      invalid: errors.sidecar_format,
                    })}
                    value={this.state.sidecar_format}
                    onChange={this.onChange}
                    id="sidecar_format"
                  >
                    <option>Subtitle File Format</option>
                    <option value="SRT">SRT</option>
                    <option value="VTT">VTT</option>
                    <option value="STL">STL</option>
                    <option value="XML">XML</option>
                    <option value="SCC">SCC</option>
                    <option value="ASS">ASS</option>
                    <option value="SSA">SSA</option>
                    <option value="SUB">SUB</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Col>
              </React.Fragment>
            ) : this.state.format === "AD" ? (
              <React.Fragment>
                <Col>
                  <Form.Label>Sidecar Type</Form.Label>

                  <Form.Select
                    className={classnames("input-field", {
                      invalid: errors.sidecar_type,
                    })}
                    value={this.state.sidecar_type}
                    onChange={this.onChange}
                    id="sidecar_type"
                  >
                    <option>Audio Desc. Type</option>
                    <option value="Audio">Audio</option>
                    <option value="Text-based">Text-based</option>
                    <option value="Extended">Extended</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label>Sidecar File Format</Form.Label>

                  <Form.Select
                    className={classnames("input-field", {
                      invalid: errors.sidecar_format,
                    })}
                    value={this.state.sidecar_format}
                    onChange={this.onChange}
                    id="sidecar_format"
                  >
                    <option>File Format</option>
                    <option value="WAV">WAV</option>
                    <option value="MP4">MP4</option>
                    <option value="MP3">MP3</option>
                    <option value="OGG">OGG</option>
                    <option value="WebVTT">WebVTT</option>
                    <option value="TXT">TXT</option>
                    <option value="DOC">DOC</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Col>
              </React.Fragment>
            ) : null}
          </Row>

          <Form.Label>Sidecar Language</Form.Label>

          <Form.Control
            className={classnames("input-field", {
              invalid: errors.language,
            })}
            placeholder="Language"
            value={this.state.language}
            onChange={this.onChange}
            id="language"
          />
          <Form.Label>Sidecar by</Form.Label>

          <Form.Control
            className={classnames("input-field", {
              invalid: errors.sidecar_by,
            })}
            placeholder="Created by"
            value={this.state.sidecar_by}
            onChange={this.onChange}
            id="sidecar_by"
          />
          <Form.Label>Commissioned by</Form.Label>

          <Form.Control
            className={classnames("input-field", {
              invalid: errors.commissioned_by,
            })}
            placeholder="Commissioned by"
            value={this.state.commissioned_by}
            onChange={this.onChange}
            id="commissioned_by"
          />
          <Form.Label>Contact</Form.Label>

          <Form.Control
            className={classnames("input-field", {
              invalid: errors.contact,
            })}
            placeholder="Contact"
            value={this.state.contact}
            onChange={this.onChange}
            id="contact"
          />
          <Row>
            <Col>
              <Form.Label>Certificate</Form.Label>
              <Form.Control
                className={classnames("input-field", {
                  invalid: errors.certificate,
                })}
                placeholder="Certificate"
                value={this.state.certificate}
                onChange={this.onChange}
                id="certificate"
                style={{ marginBottom: "3px" }}
              />
              {film.certificate_check &&
              film.certificate === film.certificate_check ? (
                <p style={{ color: "#63c27e" }}>
                  TMDB Certificate: {film.certificate_check}
                </p>
              ) : film.certificate_check &&
                film.certificate !== film.certificate_check ? (
                <p style={{ color: "#f52525" }}>
                  TMDB Certificate: {film.certificate_check}
                </p>
              ) : null}
            </Col>
            <Col>
              <Form.Label>Frame Rate</Form.Label>
              <Form.Control
                className={classnames("input-field", {
                  invalid: errors.frame_rate,
                })}
                placeholder="Frame Rate"
                value={this.state.frame_rate}
                onChange={this.onChange}
                id="frame_rate"
              />
            </Col>
            <Col>
              <Form.Label>Quality Level</Form.Label>

              <Form.Select
                className={classnames("input-field", {
                  invalid: errors.quality_level,
                })}
                value={this.state.quality_level}
                onChange={this.onChange}
                id="quality_level"
              >
                <option>Quality Level</option>
                <option value="Professional">Professional</option>
                <option value="Non-Professional">Non-Professional</option>
              </Form.Select>
            </Col>
          </Row>
          <p>Note: this will send your listing back to draft mode, pending approval by moderators</p>
          <button type="submit">Save Changes</button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  film: state.film,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getFilm,
  editFilm,
  getTMDB,
  setTMDB,
})(EditFilmByUser);
