import React, { Component } from "react";
import downarrowwhite from "../../assets/downarrowwhite.png";
import uparrowwhite from "../../assets/uparrowwhite.png";
import { Link } from "react-router-dom";
import GlossaryOne from "./GlossaryOne";
import GlossaryTwo from "./GlossaryTwo";

import "./Static.css";

export default class FAQ extends Component {
  constructor() {
    super();

    this.state = {
      showGlossaryOne: false,
      showGlossaryTwo: false,
      FAQs: [
        {
          id: 0,
          question: "What is Sidecard?",
          answer: (
            <p>
              Sidecard is a database of access materials produced for films.
              Users can search and upload details of descriptive subtitles,
              audio description files and other materials related to cinema
              accessibility. It is not a depository for the files themselves,
              but it tracks who made the files, who for, who may be contacted to
              make use of them, as well as further useful information, such as
              which version of a film the materials were made for.
            </p>
          ),
          shown: false,
        },
        {
          id: 1,
          question: "What are the aims of Sidecard?",
          answer: (
            <p>
              Sidecard aims to support the use of access materials for film
              screenings. Recording which files are in existence and who may be
              contacted to make use of them will, by extension, promote
              accessible screenings. Sidecard, as a resource, also hopes to
              bridge the knowledge gap which discourages film-makers,
              distributors and exhibitors to facilitate accessible screenings.
              Sidecard aims to promote the further use of access materials that
              might otherwise be under-utilised, if at all utilised, and also to
              minimise duplication of efforts across the sector.
            </p>
          ),
          shown: false,
        },
        {
          id: 2,
          question: "Who is Sidecard for?",
          answer: (
            <p>
              Sidecard is intended to be used by film-makers, producers,
              festivals, distributors, venues and general audiences. Users can
              both upload details of available or planned access materials and
              search for existing access materials.
            </p>
          ),
          shown: false,
        },
        {
          id: 3,
          question: "Who moderates Sidecard?",
          answer: (
            <p>
              Sidecard is currently moderated by members of Matchbox Cinesub.
            </p>
          ),
          shown: false,
        },
        {
          id: 4,
          question: "Why is it called “Sidecard”?",
          answer: (
            <p>
              “Sidecard” is short for “Sidecar Database”. A sidecar file is an
              external file used in conjunction with video material - so, a
              subtitle or audio description file which is separate from the
              video file it was created for, rather than burned-in or mixed-in
              to the video itself.
            </p>
          ),
          shown: false,
        },
        {
          id: 5,
          question: "Who established Sidecard?",
          answer: (
            <p>
              Sidecard is a joint initiative of&nbsp;
              <a
                href="https://matchboxcineclub.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Matchbox Cine
              </a>
              ,&nbsp;
              <a
                href="https://filmhubwales.org/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Film Hub Wales
              </a>{" "}
              /&nbsp;
              <a
                href="https://inclusivecinema.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Inclusive Cinema
              </a>
              ,&nbsp;
              <a
                href="https://www.filmhubscotland.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Film Hub Scotland
              </a>{" "}
              and&nbsp;
              <a
                href="https://www.independentcinemaoffice.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Independent Cinema Office
              </a>
              . A steering group was convened in 2020 to develop a resource that
              would track and promote access materials for films, to encourage
              their use and promote accessible screenings.
            </p>
          ),
          shown: false,
        },
        {
          id: 6,
          question: "Why “descriptive subtitles” rather than “captions”?",
          answer: (
            <p>
              Confusion around subtitling terminology ultimately undermines
              access provision. Terms like “subtitles” and “captions” are often
              used interchangeably, alongside “subtitles for the deaf and hard
              of hearing (SDH)” or “HoH”. “Captions” is commonly used in the US,
              can also refer to text under photographs or images and as a word
              its meaning isn’t self-evident. The most basic and universal
              distinction is between a) subtitles which transcribe dialogue only
              and b) subtitles which also describe aspects of the film which are
              not visually intelligible. And, since the latter are also useful
              to those for whom English is not their first language, or who are
              neuro-atypical - and because they’re increasingly used by a
              general audience - “descriptive subtitles” is the most useful and
              accurate term. Further clarification of terminology can be found
              in our <Link to="/faq">Glossary</Link>.
            </p>
          ),
          shown: false,
        },
        {
          id: 7,
          question: "What are “DS-Only” or “SDH-Only” files?",
          answer: (
            <p>
              Whenever it's necessary or unavoidable to present a film with
              burned-in subtitles for the spoken dialogue, a sidecar subtitle
              file can be produced containing only the descriptive elements.
              Such files are necessary when a film would otherwise be
              inaccessible, but are generally compromised and incomplete files,
              since necessary information must be edited out in order to not
              clash with or obscure the burned-in subtitles.
            </p>
          ),
        },
        {
          id: 8,
          question: "Can I download/upload subtitle files?",
          answer: (
            <p>
              Sidecard does not currently host any access materials for download
              and has no current plans to do so. Some film-makers or festivals
              may be happy for the files they have created to be available for
              direct download, but there are logistical and legal reasons why
              that would be generally impractical for Sidecard to manage.
              Subtitle files are often used to delineate the limits of contracts
              in different territories, and rights to a film and its materials
              may change over time. In order that the site could be immediately
              and widely accessible, Sidecard simply tracks the materials and
              will endeavour to keep details for those who can be contacted to
              provide files and authorise their use as up-to-date as possible.
            </p>
          ),
          shown: false,
        },
        {
          id: 9,
          question: "Can anyone upload details of subtitle files?",
          answer: (
            <p>
              Yes, though single and bulk uploads are moderated before they are
              published, unless they come from a designated super-user or admin.
              The process will not be immediate, but if you find you have waited
              more than a day or so for your upload to be published please
              contact{" "}
              <Link
                to="#"
                onClick={() => window.open("mailto:hello@sidecard.co.uk")}
                target="_blank"
                rel="noopener noreferrer"
              >
                hello@sidecard.co.uk
              </Link>
              .
            </p>
          ),
          shown: false,
        },
        {
          id: 10,
          question: "How do I become a designated super-user?",
          answer: (
            <React.Fragment>
              <p>
                Once you have created a user account for Sidecard and
                established a pattern of reliable uploads, moderators will
                change your account status.
              </p>
              <p>
                <em>
                  If you have any further questions about this process, please
                  contact{" "}
                  <Link
                    to="#"
                    onClick={() => window.open("mailto:hello@sidecard.co.uk")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    hello@sidecard.co.uk
                  </Link>
                  .
                </em>
              </p>
            </React.Fragment>
          ),
          shown: false,
        },
        {
          id: 11,
          question: "What should I pay for access materials?",
          answer: (
            <React.Fragment>
              <p>
                Different providers and practitioners have different rates and
                rates also differ according to the work required. We advise you
                to check our <a href="/guides#resources">Resources</a> for a
                list of UK access providers and funders.
              </p>
              <p>
                <em>
                  If you wish to be added to the list of recommended providers,
                  please contact{" "}
                  <Link
                    to="#"
                    onClick={() => window.open("mailto:hello@sidecard.co.uk")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    hello@sidecard.co.uk
                  </Link>
                  .
                </em>
              </p>
            </React.Fragment>
          ),
          shown: false,
        },
        {
          id: 12,
          question:
            "What should I charge for use of existing access materials?",
          answer: (
            <p>
              The responsibility for sharing and/or monetising access materials
              remains with the commissioner/owner. Whoever manages the files and
              the rights can decide for themselves, when contacted, how the
              files may be shared. There is an argument for spreading the cost
              of access to minimise the burden on individual festivals,
              exhibitors, venues, etc. In principle, however, we recommend that
              access materials be made as freely available as possible, as soon
              as possible, especially when their creation has been funded or
              subsidised. To that end, we encourage festivals to proactively
              share any materials they have commissioned with the original
              film-makers, producers and/or distributors, so that they may then
              share them in support of future screenings.
            </p>
          ),
          shown: false,
        },
        {
          id: 13,
          question:
            "Where can I access further advice and resources to support audience development?",
          answer: (
            <p>
              Check our <a href="/guides#resources">Resources</a> for advice and
              resources as well as links to funders and available funds.
            </p>
          ),
          shown: false,
        },
      ],
    };
    this.showGlossaryOne = this.showGlossaryOne.bind(this);
    this.showGlossaryTwo = this.showGlossaryTwo.bind(this);

    this.showFAQ = this.showFAQ.bind(this);
  }

  showGlossaryOne() {
    this.setState({ showGlossaryOne: true, showGlossaryTwo: false });
  }

  showGlossaryTwo() {
    this.setState({ showGlossaryTwo: true, showGlossaryOne: false });
  }

  showFAQ() {
    this.setState({ showGlossaryOne: false, showGlossaryTwo: false });
  }

  onClick(id) {
    const qs = this.state.FAQs;
    qs[id].shown = !this.state.FAQs[id].shown;
    this.setState({
      FAQs: qs,
    });
  }

  render() {
    const FAQs = this.state.FAQs.map((q) => {
      return (
        <React.Fragment>
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={() => this.onClick(q.id)}
          >
            <h5>{q.question}</h5>
            {q.shown ? (
              <img src={uparrowwhite} alt="See Less" />
            ) : (
              <img src={downarrowwhite} alt="See More" />
            )}
          </div>
          {q.shown ? q.answer : null}
          <hr style={{ marginTop: "8px" }} />
        </React.Fragment>
      );
    });

    return (
      <div className="middle-body faqs">
        <h1>FAQ / Glossary</h1>
        <br />
        <div style={{"padding":"56.25% 0 0 0", "position":"relative"}}>
          <iframe
            src="https://player.vimeo.com/video/720033755?h=b0283b51fe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style={{ position:"absolute", top:"0px", left:"0px", width:"100%", height:"100%" }}
            title="Sidecard FAQ.mp4"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <br />
        <br />
        <div className="d-flex">
          <button onClick={this.showFAQ}>Show FAQ</button>
          <button onClick={this.showGlossaryOne}>Show General Glossary</button>
          <button onClick={this.showGlossaryTwo}>
            Show File Format Glossary
          </button>
        </div>
        {this.state.showGlossaryOne ? (
          <GlossaryOne />
        ) : this.state.showGlossaryTwo ? (
          <GlossaryTwo />
        ) : (
          <React.Fragment>
            {FAQs}
            <br />
            <p>
              <em>
                If you have any questions that are not covered here, please
                contact{" "}
                <Link
                  to="#"
                  onClick={() => window.open("mailto:hello@sidecard.co.uk")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hello@sidecard.co.uk
                </Link>
                .
              </em>
            </p>
          </React.Fragment>
        )}
      </div>
    );
  }
}
