import axios from "axios";
import store from '../store'; // Import the Redux store

import { GET_ERRORS, GET_FILMS, FILM_LOADING, GET_FILM } from "./types";

// Add Film
export const addFilm = (filmData, history) => (dispatch) => {
  axios
    .post("/api/films/add", filmData)
    .then((res) => history.push("/success"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Add Film
export const editFilm = (id, filmData, history, push, pushToProfile, queryStr) => (dispatch) => {
  axios
    .patch(`/api/films/${id}/${store.getState().auth.user.id}`, filmData)
    .then((res) => {
      if (push) {
        history.push(`/dashboard${queryStr}`);
      }
      if (pushToProfile) {
        history.push("/account");
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Add Films
export const addFilms = (filmData, history) => (dispatch) => {
  axios
    .post("/api/films/addmulti", filmData)
    .then((res) => history.push("/thanks"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Delete Film
export const deleteFilm = (filmId) => (dispatch) => {
  axios
    .delete(`/api/films/${filmId}`)
    .then((res) =>
      axios
        .get("api/films")
        .then((res) =>
          dispatch({
            type: GET_FILMS,
            payload: res.data.data,
          })
        )
        .catch((err) =>
          dispatch({
            type: GET_FILMS,
            payload: null,
          })
        )
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get Film
export const getFilm = (filmId) => (dispatch) => {
  dispatch(setFilmLoading());
  axios
    .get(`/api/films/${filmId}`)
    .then((res) =>
      dispatch({
        type: GET_FILM,
        payload: res.data.film,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_FILM,
        payload: null,
      })
    );
};

// Get TMDB details
export const getTMDB = (filmId) => (dispatch) => {
  dispatch(setFilmLoading());
  axios
    .get(`/api/films/tmdb/${filmId}`)
    .then((res) =>
      res.data.film
        ? dispatch({
            type: GET_FILM,
            payload: res.data.film,
          })
        : dispatch({
            type: FILM_LOADING,
            payload: false,
          })
    )
    .catch((err) =>
      dispatch({
        type: GET_FILM,
        payload: null,
      })
    );
};

// Set manual TMDB
export const setTMDB = (filmId, tmdbId) => (dispatch) => {
  dispatch(setFilmLoading());
  axios
    .post("/api/films/tmdb", { tmdbId, filmId })
    .then((res) =>
      res.data.film
        ? dispatch({
            type: GET_FILM,
            payload: res.data.film,
          })
        : dispatch({
            type: FILM_LOADING,
            payload: false,
          })
    )
    .catch((err) =>
      dispatch({
        type: GET_FILM,
        payload: null,
      })
    );
};

// Get Films
export const getFilms = (searchString = "") => (dispatch) => {
  dispatch(setFilmLoading());
  axios
    .get(`api/films/?${searchString}`)
    .then((res) =>
      dispatch({
        type: GET_FILMS,
        payload: res.data.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_FILMS,
        payload: null,
      })
    );
};

// Approve Films
export const approveFilms = (filmData) => (dispatch) => {
  axios
    .patch("/api/films/approve", filmData)
    .then((res) =>
      axios
        .get("api/films")
        .then((res) =>
          dispatch({
            type: GET_FILMS,
            payload: res.data.data,
          })
        )
        .catch((err) =>
          dispatch({
            type: GET_FILMS,
            payload: null,
          })
        )
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Draft Films
export const draftFilms = (filmData) => (dispatch) => {
  axios
    .patch("/api/films/draft", filmData)
    .then((res) =>
      axios
        .get("api/films")
        .then((res) =>
          dispatch({
            type: GET_FILMS,
            payload: res.data.data,
          })
        )
        .catch((err) =>
          dispatch({
            type: GET_FILMS,
            payload: null,
          })
        )
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Set loading state
export const setFilmLoading = () => (dispatch) => {
  dispatch({
    type: FILM_LOADING,
    payload: false,
  });
};
