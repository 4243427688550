import React, { Component } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import FilterButton from "./FilterButton";
import RangeSlider from "./RangeSlider";

import "./Collections.css";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    const formats = [
      {
        name: "DS",
        value: "DS",
      },
      {
        name: "DS Only",
        value: "DS Only",
      },
      {
        name: "AD",
        value: "AD",
        key_val: "format",
      },
      {
        name: "AD - Text-based",
        value: "Text-based",
      },
      {
        name: "AD - Extended",
        value: "Extended",
      },
      {
        name: "Other",
        value: "Other",
        key_val: "format",
      },
    ];
    const quality_level = [];
    const languages = [];
    const genres = [];
    props.films.forEach((film) => {
      quality_level.push({ value: film.quality_level });
      languages.push({ value: film.language });
      film.genres.forEach((genre) => {
        genres.push({ value: genre });
      });
    });

    function onlyUnique(value, index, self) {
      return self.findIndex((m) => m.value === value.value) === index;
    }

    const quality_level2 = quality_level.filter(onlyUnique);
    const languages2 = languages.filter(onlyUnique);
    const genres2 = genres.filter(onlyUnique);

    this.state = {
      formats: formats,
      quality_level: quality_level2,
      languages: languages2,
      genres: genres2,
    };
  }

  render() {
    const formats = this.state.formats.map((format) => (
      <FilterButton
        key_val={format.key_val ? format.key_val : "sidecar_type"}
        value={format.value}
        name={format.name}
        onFilter={this.props.onFilter}
      />
    ));

    const quality_level = this.state.quality_level.map((item) => (
      <FilterButton
        key_val="quality_level"
        value={item.value}
        name={item.value}
        onFilter={this.props.onFilter}
      />
    ));

    const languages = this.state.languages.map((item) => (
      <FilterButton
        key_val="language"
        value={item.value}
        name={item.value}
        onFilter={this.props.onFilter}
      />
    ));

    const genres = this.state.genres.map((item) => (
      <FilterButton
        key_val="genres"
        value={item.value}
        name={item.value}
        onFilter={this.props.onFilter}
      />
    ));

    return (
      <div style={{ minHeight: "100%" }}>
        <ProSidebar>
          <Menu style={{ margin: "0.5rem" }}>
            <MenuItem>
              <h4 style={{ fontFamily: "futura-pt-bold" }}>Filters</h4>
              <p>Type</p>
              <div className="d-flex flex-wrap">{formats}</div>
              <p>Quality Level</p>
              <div className="d-flex flex-wrap">{quality_level}</div>
              <p>Sidecar Language</p>
              <div className="d-flex flex-wrap">{languages}</div>
              <p>Duration</p>
              <RangeSlider
                min={0}
                max={300}
                onFilter={this.props.onFilter}
                key_val="duration"
              />
              <p>Year</p>
              <RangeSlider
                min={1900}
                max={2022}
                onFilter={this.props.onFilter}
                key_val="year"
              />
              <p>Genre</p>
              <div className="d-flex flex-wrap">{genres}</div>
            </MenuItem>
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}
