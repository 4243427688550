import React, { Component } from "react";
import Badge from "react-bootstrap/esm/Badge";
import classnames from "classnames";

import "./Collections.css";

export default class FilterButton extends Component {
  constructor() {
    super();
    this.state = {
      isSelected: false,
    };
  }

  render() {
    return (
      <Badge
        style={{ margin: "3px" }}
        className={classnames("filter-badge", {
            'selected': this.state.isSelected,
          })}
        onClick={() => {
          this.props.onFilter(this.props.key_val, this.props.value, !this.state.isSelected);
          this.setState({ isSelected: !this.state.isSelected });
        }}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            // Trigger the click event on Enter or Space key press
            e.preventDefault();
            e.currentTarget.click();
          }
        }}
      >
        {this.props.name}
      </Badge>
    );
  }
}
