import Papa from "papaparse";
import React, { Component } from "react";
import FilmTable from "../table/FilmTable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { addFilms } from "../../actions/filmActions";
import { withRouter } from "react-router";
import "./AddFilm.css";

class Bulk extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      data: [],
      errors: [],
      showLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        showLoading: false,
      });
    }
  }

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    // Reset data to empty
    this.setState({ data: [] });

    Papa.parse(this.state.selectedFile, {
      header: true,
      encoding: "l2",
      complete: (results) => {
        results.data.map((film) => {
          if (film.title && film.directors) {
            const directors = film.directors.split(", ");

            this.setState({
              data: [
                ...this.state.data,
                {
                  title: film.title,
                  directors: directors,
                  year: parseInt(film.year),
                  duration: parseInt(film.duration),
                  language: film.sidecar_language,
                  rights_holder: film.rights_holder,
                  format: film.category,
                  sidecar_type: film.sidecar_type,
                  sidecar_format: film.sidecar_format,
                  sidecar_by: film.sidecar_by,
                  commissioned_by: film.commissioned_by,
                  contact: film.contact,
                  certificate: film.certificate,
                  frame_rate: film.frame_rate,
                  quality_level: film.quality_level,
                  notes: film.notes,
                  author_id: this.props.auth.user.id,
                  author: this.props.auth.user.name,
                  approved: false,
                },
              ],
            });
          }
          return true;
        });
      },
    });
  };

  // On file submit (click the submit button)
  onFileSubmit = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.setState({ showLoading: true });
    this.props.addFilms(this.state.data, this.props.history);
  };

  fileData = () => {
    if (this.state.data[0] && this.state.data.length < 101) {
      return (
        <div>
          <FilmTable data={this.state.data} />
          <div className="d-flex flex-row-reverse">
            <button onClick={this.onFileSubmit}>Submit</button>
          </div>
        </div>
      );
    } else if (this.state.data.length >= 101) {
      return (
        <div>
          <br />
          <p>
            <strong>Please limit your upload to 100 listings at a time.</strong>
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <p>
            Your upload will be displayed here. Please check all information is
            accurate before you press submit.
          </p>
        </div>
      );
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="bulk">
        <h1 className="mb-3">Bulk upload via CSV</h1>

        {this.state.showLoading ? (
          <p>Processing your upload... please do not close this page.</p>
        ) : (
          <React.Fragment>
            <p className="mb-0">
              Download{" "}
              <a href="/input_template.csv" download>
                template CSV file
              </a>
              .{" "}
            </p>
            <p>
              Download{" "}
              <a href="/input_example.csv" download>
                example CSV file
              </a>
              .
            </p>
            <p>
              All bulk upload entries are moderated before posting. If you would
              like to record more than one sidecar file for the same film,
              please list each sidecar file as a new row on the csv file.
            </p>
            <p>
              Please limit your CSV files to 100 rows per file. If you have more
              than 100 listings to upload, please separate these into smaller
              files.
            </p>
            <p className="mb-4">
              If you are unsure of any element, please refer to{" "}
              <Link to="/guides">How to Use Sidecard</Link>,{" "}
              <Link to="/faq">Glossary</Link> and/or <Link to="/faq">FAQ</Link>.
              With any further questions, please contact{" "}
              <Link
                to="#"
                onClick={() => window.open("mailto:hello@sidecard.co.uk")}
                target="_blank"
                rel="noopener noreferrer"
              >
                hello@sidecard.co.uk
              </Link>
              .
            </p>
            <br />
            <input type="file" onChange={this.onFileChange} />
            <button onClick={this.onFileUpload}>Upload</button>
            <br />
            <p className="red-text">{errors.title}</p>
            <p className="red-text">{errors.directors}</p>
            <p className="red-text">{errors.year}</p>
            <p className="red-text">{errors.duration}</p>
            <br />
            {this.fileData()}

            <br />
          </React.Fragment>
        )}
      </div>
    );
  }
}

Bulk.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { addFilms })(withRouter(Bulk));
