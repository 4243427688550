import { GET_FILMS, GET_FILM, FILM_LOADING } from "../actions/types";

const initialState = {
  films: [],
  film: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FILMS:
      return {
        ...state,
        films: action.payload,
        loading: false
      };
    case GET_FILM:
      return {
        ...state,
        film: action.payload,
        loading: false
      };
    case FILM_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
