import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFilms, approveFilms, draftFilms } from "../../actions/filmActions";
import Sidebar from "./Sidebar";
import FilmTable from "../table/FilmTable";
import Container from "react-bootstrap/esm/Container";
import InputGroup from "react-bootstrap/esm/InputGroup";
import Form from "react-bootstrap/esm/Form";
import qs from "query-string";
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import "./Dashboard.css";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      approval_type: "",
      approval_type_filter: "",
      access_filter: "",
      search: "",
      uploaders: [],
      uploader_filter: "",
      uploader_number: 1,
    };
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount() {
    const { location, getFilms } = this.props;
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const searchString = qs.stringify(queryParams);

    // Call getFilms action with the query parameters
    getFilms(searchString);
  }

  // Gets list of authors of listings on first load
  componentWillReceiveProps(nextProps) {
    const uploaders = [];
    nextProps.film.films.forEach((film) => {
      uploaders.push({ id: film.author_id, value: film.author });
    });

    function onlyUnique(author, index, self) {
      return self.findIndex((m) => m.id === author.id) === index;
    }

    const uploaders_unique = uploaders.filter(onlyUnique);
    if (uploaders_unique.length > this.state.uploader_number) {
      this.setState({
        uploaders: uploaders_unique,
        uploader_number: uploaders_unique.length,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearchChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.onFilter(e.target.value);
  };

  markAsApproved(props) {
    if (this.state.approval_type === "Approved") {
      props.approveFilms(this.state.data);
      this.setState({ data: [] })
    } else if (this.state.approval_type === "Draft") {
      props.draftFilms(this.state.data);
      this.setState({ data: [] })
    }
  }

  onSelect(film, isChecked) {
    if (isChecked) {
      this.setState((previousState) => ({
        data: [...previousState.data, film._id],
      }));
    } else {
      // remove from array
      this.setState((prevState) => ({
        data: prevState.data.filter((afilm) => afilm !== film._id),
      }));
    }
  }

  onFilter(search = false) {
    const query = {};
    if (this.state.access_filter) {
      query["format"] = this.state.access_filter;
      query["search"] = this.state.search;
    }
    if (this.state.approval_type_filter) {
      query["approved"] = this.state.approval_type_filter;
      query["search"] = this.state.search;
    }
    if (this.state.uploader_filter) {
      query["author_id"] = this.state.uploader_filter;
      query["search"] = this.state.search;
    }
    if (this.state.search && search) {
      query["search"] = search;
    }
    const searchString = qs.stringify(query);
    this.props.getFilms(searchString);

    const { history } = this.props;
    const url = `${window.location.pathname}?${searchString}`;

    history.push(url);
  }

  render() {
    const { user } = this.props.auth;
    const { films } = this.props.film;

    const uploaders = this.state.uploaders.map((uploader) => (
      <option value={uploader.id}>{uploader.value}</option>
    ));

    return (
      <div className="dashboard">
        <Sidebar />
        <div style={{ width: "100%" }}>
          <Container>
            <h4 style={{ margin: "50px 0 20px 20px" }}>
              Hey, {user.name.split(" ")[0]}. Welcome to your dashboard!
            </h4>
            <InputGroup
              style={{
                maxWidth: "50%",
                marginBottom: "10px",
                marginLeft: "20px",
              }}
            >
              <Form.Control
                style={{
                  fontSize: "1.1em",
                  border: "none",
                  fontWeight: "600",
                }}
                id="search"
                placeholder="Search film or director..."
                onChange={this.onSearchChange}
                value={this.state.search}
              />
              <InputGroup.Text style={{ background: "white", border: "none" }}>
                <i className="bi bi-search p-0"></i>
              </InputGroup.Text>
            </InputGroup>
            <p style={{ margin: "5px 20px" }}>
              <strong>Filters</strong>
            </p>

            <div className="d-flex">
              <Form.Select
                className="select"
                onChange={this.onChange}
                value={this.state.approval_type_filter}
                id="approval_type_filter"
              >
                <option value="">Status</option>
                <option value={true}>Approved</option>
                <option value={false}>Draft</option>
              </Form.Select>
              <Form.Select
                className="select"
                onChange={this.onChange}
                value={this.state.access_filter}
                id="access_filter"
              >
                <option value="">Access</option>
                <option value="AD">AD</option>
                <option value="DS">DS</option>
                <option value="Other">Other</option>
              </Form.Select>
              <Form.Select
                className="select"
                onChange={this.onChange}
                value={this.state.uploader}
                id="uploader_filter"
              >
                <option value="">Uploaded by</option>
                {uploaders}
              </Form.Select>
              <button onClick={() => this.onFilter()}>Filter</button>
            </div>
            <p style={{ margin: "5px 20px" }}>
              <strong>Actions</strong>
            </p>

            <div className="d-flex align-items-center">
              <Form.Select
                className="select"
                onChange={this.onChange}
                value={this.state.approval_type}
                id="approval_type"
              >
                <option>Update Status</option>
                <option value="Approved">Approved</option>
                <option value="Draft">Draft</option>
              </Form.Select>
              <button onClick={() => this.markAsApproved(this.props)}>
                Apply (and send approval emails)
              </button>
            </div>

            {films[0] ? (
              <FilmTable
                data={films}
                isDashboard={true}
                onSelect={this.onSelect}
              />
            ) : (
              <p style={{ marginLeft: "20px" }}>No results found.</p>
            )}
          </Container>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  getFilms: PropTypes.func.isRequired,
  film: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  film: state.film,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { getFilms, approveFilms, draftFilms })
)(Dashboard);
