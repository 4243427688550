import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Static.css";

export default class HowTo extends Component {
  render() {
    return (
      <div className="middle-body static how-to">
        <h1>How-To Guides</h1>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/720033441?h=2519ceed78&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
            }}
            title="Sidecard How-To.mp4"
          ></iframe>
        </div>
        <br />
        <br />
        <div className="d-flex flex-wrap">
          <a href="#how-to">
            <button>How To Use Sidecard</button>
          </a>
          <a href="#distributors">
            <button>Guide for Distributors</button>
          </a>
          <a href="#festivals">
            <button>Guide for Festivals</button>
          </a>
          <a href="#film-makers">
            <button>Guide for Film-makers</button>
          </a>
          <a href="#venues">
            <button>Guide for Venues</button>
          </a>
          <a href="#resources">
            <button className="resources">Resources</button>
          </a>
        </div>
        <div id="how-to">
          <br />
          <br />
          <div className="d-flex align-items-left">
            <h2>How To Use Sidecard</h2>
            <a href="/files/How To Use Sidecard.pdf" download>
              <button className="little">Download as PDF</button>
            </a>
            <a href="/files/How To Use Sidecard.docx" download>
              <button className="little">Download as DOC</button>
            </a>
          </div>
          <p>
            Sidecard is a database of access materials produced for films. Users
            can search and upload details of descriptive subtitles, audio
            description files and other materials related to cinema
            accessibility. It is not a depository for the files themselves, but
            it tracks who made the files, who for, who may be contacted to make
            use of them, as well as further useful information, such as which
            version of a film the materials were made for.
          </p>
          <p>
            Before using the site, you can learn the basic terminology used
            around access materials, including the basic file types and formats
            and how to differentiate them. For example, we recommend the term
            “descriptive subtitles” when discussing subtitle files made for
            access purposes (variously known as captions, SDH, HoH), as opposed
            to basic translation/transcription subtitle files. For a detailed
            list of terminology and file formats, refer to our{" "}
            <Link to="/faq">Glossary</Link>.
          </p>
          <h4>Searching the database</h4>
          <ul>
            <li>
              <p>
                Sidecard is searchable via the search bar on the{" "}
                <Link to="/">front page</Link>. For the best results, search by
                either director or title.
              </p>
            </li>
            <li>
              <p>
                If you already have screening materials, check the frame rate of
                the video file you have. If your film is non-English language,
                confirm whether or not the video file you have already has
                burned-in subtitles (and if so, whether a clean video might be
                available).
              </p>
            </li>
          </ul>
          <p>
            If you are unsure of any element, please refer to{" "}
            <Link to="/faq">Glossary</Link> and/or <Link to="/faq">FAQ</Link>.
            With any further questions, please contact{" "}
            <Link
              to="#"
              onClick={() => window.open("mailto:hello@sidecard.co.uk")}
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@sidecard.co.uk
            </Link>
            .
          </p>
          <h4>Single file upload process</h4>
          <ul>
            <li>
              <p>
                Search Sidecard, via the search bar on the{" "}
                <Link to="/">front page</Link>, to check if a listing for your
                material already exists.
              </p>
            </li>
            <li>
              <p>
                On the <Link to="/">front page</Link>, click the{" "}
                <strong>Add Film(s)</strong> button next to the search bar.
              </p>
            </li>
            <li>
              <p>Complete the Title, Director, Year and Duration fields.</p>
            </li>
            <li>
              <p>
                For &ldquo;Sidecar Type&rdquo; select either &ldquo;Descriptive
                Subtitles&rdquo;, &ldquo;Audio Description&rdquo; or
                &ldquo;None&rdquo;.
              </p>
            </li>
            <li>
              <p>
                NB If your access material is only available burned-in or
                otherwise as part of the film/video itself, simply add pertinent
                details to the film title e.g. &ldquo;Apocalypse Now (High
                Contrast)&rdquo; or &ldquo;Apocalypse Now (AD)&rdquo;. Then, for
                Sidecar Type, select &ldquo;None&rdquo;.
              </p>
            </li>
            <li>
              <p>Complete the remaining fields.</p>
            </li>
            <li>
              <p>
                NB The &ldquo;Contact&rdquo; email address you provide will be
                publicly visible on the site.
              </p>
            </li>
            <li>
              <p>Click &ldquo;Submit for moderation&rdquo;.</p>
            </li>
            <li>
              <p>
                The film information you provide will then be compared to{" "}
                <a href="https://www.themoviedb.org/?language=en-GB">
                  The Movie Database
                </a>
                , and any errors or inconsistencies will be flagged to our
                moderators.
              </p>
            </li>
            <li>
              <p>
                Genres are automatically populated via The Movie Database
                listings. If your film is not listed on The Movie Database and
                you would like to supply additional information, including
                genres, please contact{" "}
                <Link
                  to="#"
                  onClick={() => window.open("mailto:hello@sidecard.co.uk")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hello@sidecard.co.uk
                </Link>
                .
              </p>
            </li>
            <li>
              <p>
                Moderators will contact you, if necessary, to confirm any
                details.
              </p>
            </li>
            <li>
              <p>
                Pending moderation, your upload should be visible on the site
                within hours.
              </p>
            </li>
          </ul>
          <p>
            All uploads are moderated before posting, unless you are a
            designated Sidecard super-user or admin.&nbsp;
          </p>
          <p>
            If you are unsure of any element, please refer to{" "}
            <Link to="/faq">Glossary</Link> and/or <Link to="/faq">FAQ</Link>.
            With any further questions, please contact{" "}
            <Link
              to="#"
              onClick={() => window.open("mailto:hello@sidecard.co.uk")}
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@sidecard.co.uk
            </Link>
            .
          </p>

          <h4>Bulk Upload process</h4>
          <ul>
            <li>
              <p>
                On the <Link to="/">front page</Link>, click the{" "}
                <strong>Add Film(s)</strong> button next to the search bar.
              </p>
            </li>
            <li>
              <p>
                Click the <Link to="/add">Bulk Upload</Link> button.
              </p>
            </li>
            <li>
              <p>
                Download and complete{" "}
                <a href="/input_template.csv" download>
                  the template CSV file
                </a>
                . You can also refer to{" "}
                <a href="/input_example.csv" download>
                  this pre-populated sample file
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                Complete as many fields as possible - title, director, year and
                duration are most essential.
              </p>
            </li>
            <li>
              <p>
                NB If your access material is <em>only</em> available burned-in
                or otherwise as part of the film/video itself, simply add
                pertinent details to the film title e.g. &ldquo;Apocalypse
                Now&nbsp;(High Contrast)&rdquo; or &ldquo;Apocalypse Now
                (AD)&rdquo;.
              </p>
            </li>
            <li>
              <p>
                Once complete, save it locally and then upload it via the
                buttons on the <Link to="/add">Bulk Upload</Link> page.
              </p>
            </li>
            <li>
              <p>
                The film information you provide will then be compared to&nbsp;
                <a href="https://www.themoviedb.org/?language=en-GB">
                  The Movie Database
                </a>
                , and any errors or inconsistencies will be flagged to our
                moderators.
              </p>
            </li>
            <li>
              <p>
                Genres are automatically populated via The Movie Database
                listings. If your film is not listed on The Movie Database and
                you would like to supply additional information, including
                genres, please contact{" "}
                <Link
                  to="#"
                  onClick={() => window.open("mailto:hello@sidecard.co.uk")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hello@sidecard.co.uk
                </Link>
                .
              </p>
            </li>
            <li>
              <p>
                Moderators will contact you, if necessary, to confirm any
                details.
              </p>
            </li>
            <li>
              <p>
                Pending moderation, your upload should be visible on the site
                within hours.
              </p>
            </li>
          </ul>
          <p>
            All uploads are moderated before posting, unless you are a
            designated Sidecard super-user or admin.&nbsp;
          </p>
          <p>
            If you are unsure of any element, please refer to{" "}
            <Link to="/faq">Glossary</Link> and/or <Link to="/faq">FAQ</Link>.
            With any further questions, please contact{" "}
            <Link
              to="#"
              onClick={() => window.open("mailto:hello@sidecard.co.uk")}
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@sidecard.co.uk
            </Link>
            .
          </p>
          <br />
          <p>
            <a href="#">↑ Return to top</a>
          </p>
        </div>
        <div id="distributors">
          <br />
          <div className="d-flex align-items-left">
            <h2>Guide for Distributors</h2>
            <a href="/files/Guide for Distributors _ Sidecard.pdf" download>
              <button className="little">Download as PDF</button>
            </a>
            <a href="/files/Guide for Distributors _ Sidecard.docx" download>
              <button className="little">Download as DOC</button>
            </a>
          </div>
          <p>
            Ensuring your films are accessible means they can reach the widest
            possible audience. Ensuring the technical quality of your access
            materials means exhibitors and audiences can rely upon them.
            Supporting practitioners to create your access materials means they
            will be as accurate as possible, and that the cinematic experience
            will remain immersive.
          </p>
          <ul>
            <li>
              <p>
                Most importantly, learn the basic terminology used around access
                materials, including the basic file types and formats and how to
                differentiate them. For example, we recommend the term
                &ldquo;descriptive subtitles&rdquo; when discussing subtitle
                files made for access purposes (variously known as captions,
                SDH, HoH), as opposed to basic translation/transcription
                subtitle files. For a detailed list of terminology and file
                formats, refer to our <Link to="/glossary">Glossary</Link>.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Accessibility should be considered from the earliest point
                possible. Access practitioners can work with film-makers to
                ensure the integrity of the work is respected while the demands
                of accessibility are met.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Prepare access materials for all different screening contexts
                (theatrical DCP, VOD, disc, etc). If your film is non-English
                language, it may require burned-in subtitles and an edited
                DS-only file for VOD.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Ensure your supplementary and marketing materials are also
                accessible (including trailers, clips, intros, Q&amp;As, short
                documentaries and all videos made for social media).
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Consider that BSL and descriptive subtitles are not mutually
                interchangeable. BSL is a language in itself. Many BSL users
                don&rsquo;t use subtitles and many subtitle users don&rsquo;t
                know BSL.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Bad or low quality subtitles are not necessarily better than no
                subtitles. Ineffective subtitles not only fail to create an
                equal experience, but break the spell for the entire audience.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                NB Auto-generated subtitles are not accessible. Even the very
                best still require humans with expertise to version them to make
                them fit for use.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Understand what deliverables you have that can be provided to
                exhibitors, and what these deliverables consist of - learn the
                difference between dialogue lists and descriptive subtitles,
                etc, with reference to our <Link to="/glossary">Glossary</Link>.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Ensure your materials are correctly named, according to what
                they consist of and the media they were made against.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Track and record your materials in a central record
                (spreadsheet, google sheet, etc)
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>Be responsive to exhibitors requesting access materials.</p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Be supportive of exhibitors producing their own access materials
                for your films.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Consider sharing your access materials with other distributors
                internationally, either freely or for a reasonable fee.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Sidecard is designed so that the responsibility for sharing
                and/or monetising access materials remains with the
                commissioner/owner. Whoever manages the files and the rights can
                decide for themselves, when contacted, how the files may be
                shared. There is an argument for spreading the cost of access to
                minimise the burden on individual festivals, exhibitors, venues,
                etc. In principle, however, we recommend that access materials
                be made as freely available as possible, as soon as possible,
                especially when their creation has been funded or subsidised. To
                that end, we encourage distributors to proactively share any
                materials they have commissioned with venues, exhibitors and any
                other screening partners, so that they may then employ them in
                support of future screenings.
              </p>
            </li>
          </ul>
          <p>
            If you have any questions that are not covered here, please contact{" "}
            <Link
              to="#"
              onClick={() => window.open("mailto:hello@sidecard.co.uk")}
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@sidecard.co.uk
            </Link>
            .
          </p>
          <br />
          <p>
            <a href="#">↑ Return to top</a>
          </p>{" "}
        </div>
        <div id="festivals">
          <br />
          <div className="d-flex align-items-left">
            <h2>Guide for Festivals</h2>
            <a href="/files/Guide for Festivals _ Sidecard.pdf" download>
              <button className="little">Download as PDF</button>
            </a>
            <a href="/files/Guide for Festivals _ Sidecard.docx" download>
              <button className="little">Download as DOC</button>
            </a>
          </div>
          <p>
            Ensuring your programme is accessible means it can reach the widest
            possible audience. Ensuring the technical quality of your access
            materials means venue partners and audiences can rely upon them.
            Supporting practitioners to create your access materials means they
            will be as full and accurate as possible, and that the cinematic
            experience will remain immersive.
          </p>
          <ul>
            <li>
              <p>
                Most importantly, learn the basic terminology used around access
                materials, including the basic file types and formats and how to
                differentiate them. For example, we recommend the term
                &ldquo;descriptive subtitles&rdquo; when discussing subtitle
                files made for access purposes (variously known as captions,
                SDH, HoH), as opposed to basic translation/transcription
                subtitle files. For a detailed list of terminology and file
                formats, refer to our <Link to="/glossary">Glossary</Link>.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Build access into your budget from the earliest point. Consider
                it an integral, ring-fenced part of your budget. Including
                access provision in your application to funders will improve its
                general chances of success, without diminishing your working
                budget.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>Engage access practitioner(s) as early as possible.</p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Integrate access practitioners into your workflow as much as you
                and they are comfortable, e.g. invite them to participate in
                operational spreadsheets, create dedicated spreadsheets to track
                the access provision, invite them to join shared drives, give
                them access to your online platforms, etc.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Consider (in collaboration with an access practitioner, if
                possible) how your programme will be accessed and how this will
                impact access provision, e.g. if your IRL screening requires
                burned-in subtitles, if your online platform cannot support
                raised subtitles, etc.&nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Plan your access provision according to your operational
                capacity. It might be more &nbsp;manageable to make one aspect
                of your programme fully accessible and then build
                &nbsp;provision over time. That is preferable to overreaching
                and failing to deliver advertised access provision. On the other
                hand&hellip;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                ..be ambitious. With the requisite planning and resources,
                it&rsquo;s possible to make your programme as accessible as
                possible.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Keep track of work required/materials requested/work completed
                in a dedicated spreadsheet (you can use or adapt
                Sidecard&rsquo;s bulk upload CSV as a template)
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Request materials from film-makers, distributors and sales
                agents with access provision in mind - clean videos and sidecar
                files wherever possible, and request any and all deliverable
                materials that might assist access.&nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Materials you should request from sales agents, distributors
                and/or film-makers: &ldquo;any scripts, transcripts, dialogue
                lists, CCSL docs, music cue sheets and/or any
                existing subtitles, descriptive subtitles, captions, SDH, HoH,
                etc &nbsp;- anything that can be used to aid research and ensure
                full and accurate access materials.&rdquo;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Don&rsquo;t assume distributors, sales agents, producers or
                film-makers have a clear understanding of what access materials
                constitute - they may think &ldquo;captions&rdquo; and
                &ldquo;subtitles&rdquo; are interchangeable terms and so may
                offer basic subtitle files instead of descriptive ones, or they
                may not know that they have descriptive subtitles to offer
                (it&rsquo;s not unknown for these files to be misnamed/misfiled
                at source)
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Develop strategies for collaborating with film-makers who may be
                circumspect about having their work open-subtitled, or subtitled
                at all.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Ensure your access provision is foregrounded wherever possible:
                internal and external listings, posters, social posts
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Ensure your accessible screenings are planned, scheduled and
                advertised in concert with the rest of your programme - changing
                already on-sale general screenings into accessible screenings
                risks unnecessarily provoking the ire of ticket holders, and not
                clearly advertising them to potential audiences in the first
                place means the provision will be squandered.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Sidecard is designed so that the responsibility for sharing
                and/or monetising access materials remains with the
                commissioner/owner. Whoever manages the files and the rights can
                decide for themselves, when contacted, how the files may be
                shared. There is an argument for spreading the cost of access to
                minimise the burden on individual festivals, exhibitors, venues,
                etc. In principle, however, we recommend that access materials
                be made as freely available as possible, as soon as possible,
                especially when their creation has been funded or subsidised. To
                that end, we encourage festivals to proactively share any
                materials they have commissioned with the original film-makers,
                producers and/or distributors, so that they may then employ them
                in support of future screenings.
              </p>
            </li>
          </ul>
          <p>
            If you have any questions that are not covered here, please contact{" "}
            <Link
              to="#"
              onClick={() => window.open("mailto:hello@sidecard.co.uk")}
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@sidecard.co.uk
            </Link>
            .
            <br />
            <p>
              <a href="#">↑ Return to top</a>
            </p>
          </p>
        </div>
        <div id="film-makers">
          <br />
          <div className="d-flex align-items-left">
            <h2>Guide for Film-makers</h2>
            <a href="/files/Guide for Film-makers _ Sidecard.pdf" download>
              <button className="little">Download as PDF</button>
            </a>
            <a href="/files/Guide for Film-makers _ Sidecard.docx" download>
              <button className="little">Download as DOC</button>
            </a>
          </div>
          <p>
            Ensuring your films are accessible means they can reach the widest
            possible audience. Ensuring the technical quality of your access
            materials means exhibitors and audiences can rely upon them.
            Supporting practitioners to create your access materials means they
            will be as full and accurate as possible, and that the cinematic
            experience will remain immersive.
          </p>
          <ul>
            <li>
              <p>
                Most importantly, learn the basic terminology used around access
                materials, including the basic file types and formats and how to
                differentiate them. For example, we recommend the term
                &ldquo;descriptive subtitles&rdquo; when discussing subtitle
                files made for access purposes (variously known as captions,
                SDH, HoH), as opposed to basic translation/transcription
                subtitle files. For a detailed list of terminology and file
                formats, refer to our <Link to="/glossary">Glossary</Link>.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Subtitles can be hugely impactful in shaping the experience of a
                film, so it follows that they should be created with as much
                care and attention as any other aspect of the film.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                When making your film, consider that the subtitles will appear
                in the lower third of the screen. Any essential information that
                appears there will be obscured by the subtitles, or the
                subtitles will have to navigate if they can (often not possible
                in an online context). This includes any burned-in subtitles or
                graphics, but also other dramatically-relevant visual
                information.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Think carefully before employing subtitles in a non-access
                fashion, i.e. as commentary without actual dialogue. Even if
                there&rsquo;s no audio, the descriptive subtitles will still
                have to relate that, since the appearance of any subtitles at
                all implies spoken dialogue (e.g those incidences might also
                require descriptive subtitles along the lines of &ldquo;[No
                audio dialogue]&rdquo;). If subtitles are used as graphic text,
                perhaps they can be positioned on a part of the screen that
                would leave the general subtitling area clear.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Exercise caution with &ldquo;creative&rdquo; captioning. If a
                more ambitious approach to captioning (animated, illustrated,
                multi-coloured, unusual type face, etc) will risk the timely
                delivery of your access material, or generally compromise its
                effectiveness, perhaps reconsider. Access materials must strike
                a careful balance between the integrity of the film and the
                demands of access. It&rsquo;s ideal that you are part of that
                process, but good access practitioners know how to protect your
                work.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Prepare/ commission access materials for a variety of contexts -
                e.g. if your film has a number of audio languages, requiring one
                or more to be burned-in for online screenings, you will also
                have to provide a DS-only subtitle file, or embrace burning-in
                the full DS file (which would incorporate all the spoken
                dialogue, as well as descriptive elements)
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Sidecard is designed so that the responsibility for sharing
                and/or monetising access materials remains with the
                commissioner/owner. Whoever manages the files and the rights can
                decide for themselves, when contacted, how the files may be
                shared. There is an argument for spreading the cost of access to
                minimise the burden on individual festivals, exhibitors, venues,
                etc. In principle, however, we recommend that access materials
                be made as freely available as possible, as soon as possible,
                especially when their creation has been funded or subsidised. To
                that end, we encourage film-makers to proactively share any
                materials they have created or commissioned with festivals,
                exhibitors, distributors and/or any other screening partners, so
                that they may then employ them in support of future screenings.
              </p>
            </li>
          </ul>
          <p>
            If you have any questions that are not covered here, please contact{" "}
            <Link
              to="#"
              onClick={() => window.open("mailto:hello@sidecard.co.uk")}
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@sidecard.co.uk
            </Link>
            .
          </p>
          <br />
          <p>
            <a href="#">↑ Return to top</a>
          </p>{" "}
        </div>
        <div id="venues">
          <br />
          <div className="d-flex align-items-left">
            <h2>Guide for Venues</h2>
            <a href="/files/Guide for Venues _ Sidecard.pdf" download>
              <button className="little">Download as PDF</button>
            </a>
            <a href="/files/Guide for Venues _ Sidecard.docx" download>
              <button className="little">Download as DOC</button>
            </a>
          </div>
          <p>
            Ensuring your programme is accessible means you can reach the widest
            possible audience. Ensuring the technical quality of your access
            materials means audiences can rely upon them. There is an enormous
            potential audience for open-subtitled screenings but any audience is
            built on trust, reliability and continuity. Supporting practitioners
            to create access materials means they will be as full and accurate
            as possible, and that the cinematic experience will remain
            immersive.
          </p>
          <p>
            Most importantly, learn the basic terminology used around access
            materials, including the basic file types and formats and how to
            differentiate them. For example, we recommend the term
            &ldquo;descriptive subtitles&rdquo; when discussing subtitle files
            made for access purposes (variously known as captions, SDH, HoH), as
            opposed to basic translation/transcription subtitle files. For a
            detailed list of terminology and file formats, refer to our{" "}
            <Link to="/faq">Glossary</Link>.
          </p>
          <ul>
            <li>
              <p>
                Advertise your access provision, clearly, prominently and
                consistently in all contexts - online listings, print and online
                marketing, socials, etc.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Make sure you&rsquo;re using accessible forms of marketing and
                communication, including accessible social media posts. There
                are a range of useful resources on our{" "}
                <a href="#resources">Resources</a> page.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Take steps to ensure your advertised access provision is
                consistently delivered, without technical disruptions -
                especially those which may cause last-minute cancellations.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Disability and Deaf audiences are not monolithic and will very
                likely not come out en masse for a single, standalone screening
                or event, especially in an off-peak time slot.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Audiences with access needs are not&nbsp;only&nbsp;interested in
                their own stories. For example, Deaf audiences are not just
                interested in Deaf-themed screenings. While it is particularly
                unfortunate for deaf-focussed content not to be accessible,
                audiences want to be able to access your entire programme, all
                the time.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Seek to be informed, confident and persuasive in addressing any
                negative audience feedback for open-subtitled screenings.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                A useful analogy is wheelchair access for venues - you
                wouldn&rsquo;t put out your ramp just one day a week, or for
                only several hours on that one day.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                BSL is a language and not interchangeable with descriptive
                subtitles.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Detail your access provisions and venue&rsquo;s access
                facilities (accessible toilets, lifts, hearing loops etc.) on
                your website. If the lift is broken, make sure that this is
                communicated clearly on your social media, website, and booking
                process
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                Provide regular Disability and Deafness awareness training for
                staff to ensure the best experience for audiences.&nbsp;
              </p>
            </li>
          </ul>
          <p>
            <em>
              Check our <a href="#resources">Resources</a> for further reading,
              training and funding resources
            </em>
          </p>
          <p>
            If you have any questions that are not covered here, please contact{" "}
            <Link
              to="#"
              onClick={() => window.open("mailto:hello@sidecard.co.uk")}
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@sidecard.co.uk
            </Link>
            .
          </p>
          <br />
          <p>
            <a href="#">↑ Return to top</a>
          </p>{" "}
        </div>
        <div id="resources">
          <br />
          <h2>Resources</h2>
          <p>
            Below are links to where you can access more information and advice,
            as well as links to funders and available funds.
          </p>
          <ul>
            <li>
              <p>
                <a href="https://subtitletools.com/">Subtitle Tools</a> (Free,
                supports some useful fixes for problem subtitle files)
              </p>
            </li>
            <li>
              <p>
                <a href="https://github.com/SubtitleEdit/subtitleedit/releases">
                  Subtitle Edit
                </a>{" "}
                (Free, PC Only, software for creating, editing and versioning
                subtitle files)
              </p>
            </li>
            <li>
              <p>
                <a href="https://mediaarea.net/en/MediaInfo">MediaInfo</a>{" "}
                (Free, identifies attributes of your video materials, including
                frame rate)
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <a href="https://inclusivecinema.org/">Inclusive Cinema</a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.yourlocalcinema.com/">Your Local Cinema</a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://accessiblescreeningsuk.co.uk/">
                  Accessible Screenings UK
                </a>
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <a href="https://www.independentcinemaoffice.org.uk/advice-support/visually-impaired-audience-toolkit/">
                  Developing Visually Impaired Audiences toolkit (ICO)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.independentcinemaoffice.org.uk/advice-support/developing-deaf-audiences-for-film/">
                  Developing Deaf audiences for film (ICO)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.independentcinemaoffice.org.uk/advice-support/how-do-i-make-my-cinema-inclusive-and-accessible/">
                  How do I make my cinema inclusive and accessible? (ICO)
                </a>
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <a href="https://www.filmhubscotland.com/resources/widening-access-and-inclusion-online-marketing-and-screenings/">
                  Widening Access and Inclusion Online: Marketing and Screenings
                  (Film Hub Scotland)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.filmhubscotland.com/resources/matchbox-cineclub-how-to-captioning/">
                  Matchbox Cineclub: How-to Captioning (Film Hub Scotland)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.filmhubscotland.com/resources/sqiff-accessibility-guide/">
                  SQIFF Accessibility Guide (Film Hub Scotland)
                </a>
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <a href="https://www.independentcinemaoffice.org.uk/blog-an-equal-experience-why-descriptive-subtitling-matters">
                  Why Descriptive Subtitling matters (Matchbox Cine/ICO)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://matchboxcineclub.com/2022/05/30/petrovs-flu-case-study/">
                  Case Study: Petrov&rsquo;s Flu &amp; Accessible Foreign
                  Language Cinema (Matchbox Cine)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://filmfeels.co.uk/articles/matchbox-cine-accessibility-hybrid-hopes/20/">
                  Accessibility and Hybrid Hopes (Matchbox Cine/Film Feels)
                </a>
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <a href="https://www.stagetext.org/for-venues/resources/">
                  Resources for venues (Stagetext)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://cinemaforall.org.uk/advice-and-support/resources/advice-6th-page/people-hearing-loss/">
                  Hearing Loss Resources (Cinema For All)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.the-bigger-picture.com/articles/how-audio-description-ad-can-help-make-titles-and-venues-even-more-accessible/">
                  How audio description (AD) can help make titles and venues
                  even more accessible (The Bigger Picture)
                </a>
              </p>
            </li>
          </ul>
          <p>
            <strong>Funders/Funds</strong>
          </p>
          <p>
            There is no dedicated fund in the UK to support the production of
            access materials for cinema. However, you can contact your local BFI
            Film Hub to discuss funding for accessible screenings.
          </p>
          <ul>
            <li>
              <p>
                <a href="https://www.bfi.org.uk/get-funding-support/bring-film-wider-audience/bfi-film-audience-network/find-your-local-film-hub">
                  Find your local Film Hub (BFI)
                </a>
              </p>
            </li>
          </ul>
          <p>
            <strong>Access practitioners</strong>
          </p>
          <ul>
            <li>
              <p>
                <a href="https://www.instagram.com/collectivetext">
                  Collective Text
                </a>{" "}
                (DS)
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.day-for-night.org/subtitles">
                  Day For Night
                </a>{" "}
                (DS &amp; AD)
              </p>
            </li>
            <li>
              <p>
                <a href="https://matchboxcine.com/">Matchbox Cinesub</a> (DS
                &amp; AD)
              </p>
            </li>
            <li>
              <p>
                <a href="https://screenlanguage.co.uk/">Screen Language</a> (DS
                &amp; AD)
              </p>
            </li>
            <li>
              <p>
                <a href="https://sensormediahub.com/homepage.html">
                  Sensor Media Hub
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.stagetext.org/">Stagetext</a> (DS)
              </p>
            </li>
            <li>
              <p>
                <a href="https://vocaleyes.co.uk/">VocalEyes</a> (AD)
              </p>
            </li>
          </ul>
          <p>
            <strong>DCP services</strong>
          </p>
          <ul>
            <li>
              <p>
                <a href="https://www.cinebox.co/">Cinebox</a>
              </p>
            </li>
            <li>
              <p>
                <a href="http://en.greendcp.nl/">Green DCP</a>
              </p>
            </li>
          </ul>
          <p>
            <strong>Accessible Online Platforms</strong>
          </p>
          <ul>
            <li>
              <p>
                <a href="https://eventive.org/">Eventive</a> (DS &amp; AD)
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.shift72.com/">Shift 72</a> (DS only)
              </p>
            </li>
            <li>
              <p>
                <a href="https://vimeo.com/">Vimeo</a> (DS only)
              </p>
            </li>
            <li>
              <p>
                <a href="https://sproutvideo.com/">Sprout Video</a> (DS only)
              </p>
            </li>
          </ul>
          <p>
            <strong>Further Reading</strong>
          </p>
          <ul>
            <li>
              <p>
                <a href="https://www.theweek.co.uk/news/955356/why-have-we-all-switched-on-the-subtitles#:~:text=A%20study%20by%20Netflix%20that,subtitles%20to%20learn%20new%20languages%E2%80%9D.">
                  Why more TV viewers are switching on subtitles (The Week)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.theguardian.com/tv-and-radio/2019/jul/21/subtitles-tv-hearing-no-context-twitter-captions">
                  Lights, camera, caption! Why subtitles are no longer just for
                  the hard of hearing (The Guardian)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.bbc.co.uk/news/entertainment-arts-59259964">
                  Young viewers prefer TV subtitles, research suggests (BBC)
                </a>
              </p>
            </li>
          </ul>
          <br />
          <p>
            <a href="#">↑ Return to top</a>
          </p>
        </div>
      </div>
    );
  }
}
