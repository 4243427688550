import React, { Component } from "react";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

export default class About extends Component {
  render() {
    return (
      <div class="middle-body static">
        <h1>About</h1>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/719864521?h=3a45081ead&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
            }}
            title="Sidecard About 2.mp4"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <p style={{ marginTop: "20px" }}>
          Sidecard is an online database designed to record and track access
          materials made for films. Users can search and upload details of
          descriptive subtitles, audio description files and other materials
          related to cinema accessibility.&nbsp;
        </p>
        <p>
          Recording which files are in existence and who may be contacted to
          make use of them will, by extension, promote accessible screenings and
          ensure that films can reach the widest possible audience. As a
          resource, Sidecard also hopes to bridge the knowledge gap which
          discourages film-makers, distributors and exhibitors to facilitate
          accessible screenings.
        </p>
        <p>
          Sidecard is a joint initiative of{" "}
          <a
            href="https://matchboxcineclub.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Matchbox Cine
          </a>
          ,{" "}
          <a
            href="https://filmhubwales.org/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Film Hub Wales
          </a>{" "}
          /{" "}
          <a
            href="https://inclusivecinema.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Inclusive Cinema
          </a>
          ,{" "}
          <a
            href="https://www.filmhubscotland.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Film Hub Scotland
          </a>{" "}
          and{" "}
          <a
            href="https://www.independentcinemaoffice.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Independent Cinema Office
          </a>
          .
        </p>
        <h4>
          Follow us on:{" "}
          <a
            href="https://www.twitter.com/cinesidecard/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingRight: "5px" }}
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.facebook.com/cinesidecard/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingRight: "5px" }}
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/cinesidecard/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingRight: "5px" }}
          >
            <FaInstagram />
          </a>
        </h4>
      </div>
    );
  }
}
