import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { connect } from "react-redux";
import { addFilm } from "../../actions/filmActions";
import { Link } from "react-router-dom";
import Bulk from "./Bulk.js";

import "./AddFilm.css";

class AddFilm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      directors: [],
      year: "",
      duration: "",
      format: "",
      sidecar_type: "",
      sidecar_format: "",
      language: "",
      sidecar_by: "",
      commissioned_by: "",
      contact: "",
      certificate: "",
      frame_rate: "",
      quality_level: "",
      approved: false,
      errors: {},
      showDSForm: false,
      showADForm: false,
      showNoneForm: false,
      showBulk: false,
    };

    this.showSingle = this.showSingle.bind(this);
    this.showBulk = this.showBulk.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  showSingle() {
    this.setState({ showBulk: false });
  }

  showBulk() {
    this.setState({ showBulk: true });
  }

  onTagChange = (e) => {
    const data = e.target.value.split(", ");
    this.setState({ [e.target.id]: data });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const newFilm = {
      title: this.state.title,
      directors: this.state.directors,
      year: this.state.year,
      duration: this.state.duration,
      format: this.state.format,
      sidecar_type: this.state.sidecar_type,
      sidecar_format: this.state.sidecar_format,
      language: this.state.language,
      sidecar_by: this.state.sidecar_by,
      commissioned_by: this.state.commissioned_by,
      contact: this.state.contact,
      certificate: this.state.certificate,
      frame_rate: this.state.frame_rate,
      quality_level: this.state.quality_level,
      author_id: this.props.auth.user.id,
      author: this.props.auth.user.name,
      approved: this.props.auth.user.isTrusted,
    };

    this.props.addFilm(newFilm, this.props.history);
  };

  render() {
    const { errors } = this.state;
    const { auth } = this.props;

    return (
      <Container className="add">
        <button onClick={this.showSingle}>Show Single Upload Form</button>
        <button onClick={this.showBulk}>Show Bulk Upload Form</button>
        {this.state.showBulk ? (
          <Bulk />
        ) : (
          <React.Fragment>
            <h1 className="mb-3">Add a film to our database</h1>
            <p>
              All entries are moderated before posting, unless you are a
              designated Sidecard super-user or admin. If you would like to
              record more than one sidecar file for the same film, please use
              the Bulk Upload Form or simply repeat the Single Upload process.
            </p>
            <h4>Key definitions:</h4>
            <p>
              DS: Subtitle file with dialogue and descriptive elements.
              <br />
              DS-Only: Subtitle file with&nbsp;<strong>only</strong> descriptive
              elements.
              <br />
              Audio: Audio file describing relevant visual action &amp;
              information.
              <br />
              Text-based: Text document describing relevant visual action &amp;
              information.
              <br />
              Extended: Audio file which extends beyond length of film, in order
              to include fulsome visual description.
            </p>
            <p className="mb-4">
              If you are unsure of any element, please refer to{" "}
              <Link to="/guides">How to Use Sidecard</Link>,{" "}
              <Link to="/faq">Glossary</Link> and/or <Link to="/faq">FAQ</Link>.
              With any further questions, please contact{" "}
              <Link
                to="#"
                onClick={() => window.open("mailto:hello@sidecard.co.uk")}
                target="_blank"
                rel="noopener noreferrer"
              >
                hello@sidecard.co.uk
              </Link>
              .
            </p>
            <Form onSubmit={this.onSubmit}>
              <Form.Group>
                <span className="red-text">{errors.title}</span>
                <Form.Control
                  className={classnames("input-field", {
                    invalid: errors.title,
                  })}
                  placeholder="Title"
                  value={this.state.title}
                  onChange={this.onChange}
                  id="title"
                />
                <span className="red-text">{errors.directors}</span>
                <Form.Control
                  className={classnames("input-field", {
                    invalid: errors.directors,
                  })}
                  placeholder="Director/s"
                  value={this.state.directors.join(", ")}
                  onChange={this.onTagChange}
                  id="directors"
                />
                <span className="red-text">{errors.year}</span>
                <Form.Control
                  className={classnames("input-field", {
                    invalid: errors.year,
                  })}
                  placeholder="Year"
                  value={this.state.year}
                  onChange={this.onChange}
                  id="year"
                />
                <span className="red-text">{errors.duration}</span>
                <Form.Control
                  className={classnames("input-field", {
                    invalid: errors.duration,
                  })}
                  placeholder="Duration (in minutes)"
                  value={this.state.duration}
                  onChange={this.onChange}
                  id="duration"
                />

                <h5 className="mb-4">Sidecar Category (select one)</h5>
                <Row className="mb-4 align-items-center">
                  <Col>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showDSForm: true,
                          showADForm: false,
                          showNoneForm: false,
                          format: "DS",
                        });
                      }}
                    >
                      Descriptive Subtitles
                    </button>
                  </Col>
                  <Col>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showADForm: true,
                          showDSForm: false,
                          showNoneForm: false,
                          format: "AD",
                        });
                      }}
                    >
                      Audio Description
                    </button>
                  </Col>
                  <Col>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showADForm: false,
                          showDSForm: false,
                          showNoneForm: true,
                          format: "Other",
                        });
                      }}
                    >
                      None
                    </button>
                  </Col>
                </Row>

                {this.state.showDSForm ? (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <Form.Select
                          className={classnames("input-field", {
                            invalid: errors.sidecar_type,
                          })}
                          value={this.state.sidecar_type}
                          onChange={this.onChange}
                          id="sidecar_type"
                        >
                          <option>Subtitle Type</option>
                          <option value="DS">DS</option>
                          <option value="DS Only">DS Only</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          className={classnames("input-field", {
                            invalid: errors.sidecar_format,
                          })}
                          value={this.state.sidecar_format}
                          onChange={this.onChange}
                          id="sidecar_format"
                        >
                          <option>Subtitle File Format</option>
                          <option value="SRT">SRT</option>
                          <option value="VTT">VTT</option>
                          <option value="STL">STL</option>
                          <option value="XML">XML</option>
                          <option value="SCC">SCC</option>
                          <option value="ASS">ASS</option>
                          <option value="SSA">SSA</option>
                          <option value="SUB">SUB</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Control
                          className={classnames("input-field", {
                            invalid: errors.language,
                          })}
                          placeholder="Subtitle Language"
                          value={this.state.language}
                          onChange={this.onChange}
                          id="language"
                        />
                      </Col>
                    </Row>
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.sidecar_by,
                      })}
                      placeholder="Created by"
                      value={this.state.sidecar_by}
                      onChange={this.onChange}
                      id="sidecar_by"
                    />
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.commissioned_by,
                      })}
                      placeholder="Commissioned by"
                      value={this.state.commissioned_by}
                      onChange={this.onChange}
                      id="commissioned_by"
                    />
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.contact,
                      })}
                      placeholder="Contact"
                      value={this.state.contact}
                      onChange={this.onChange}
                      id="contact"
                    />
                  </React.Fragment>
                ) : null}

                {this.state.showADForm ? (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <Form.Select
                          className={classnames("input-field", {
                            invalid: errors.sidecar_type,
                          })}
                          value={this.state.sidecar_type}
                          onChange={this.onChange}
                          id="sidecar_type"
                        >
                          <option>Audio Desc. Type</option>
                          <option value="Audio">Audio</option>
                          <option value="Text-based">Text-based</option>
                          <option value="Extended">Extended</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          className={classnames("input-field", {
                            invalid: errors.sidecar_format,
                          })}
                          value={this.state.sidecar_format}
                          onChange={this.onChange}
                          id="sidecar_format"
                        >
                          <option>File Format</option>
                          <option value="WAV">WAV</option>
                          <option value="MP4">MP4</option>
                          <option value="MP3">MP3</option>
                          <option value="OGG">OGG</option>
                          <option value="WebVTT">WebVTT</option>
                          <option value="TXT">TXT</option>
                          <option value="DOC">DOC</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Control
                          className={classnames("input-field", {
                            invalid: errors.language,
                          })}
                          placeholder="Audio Language (optional)"
                          value={this.state.language}
                          onChange={this.onChange}
                          id="language"
                        />
                      </Col>
                    </Row>
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.sidecar_by,
                      })}
                      placeholder="Created by"
                      value={this.state.sidecar_by}
                      onChange={this.onChange}
                      id="sidecar_by"
                    />
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.commissioned_by,
                      })}
                      placeholder="Commissioned by"
                      value={this.state.commissioned_by}
                      onChange={this.onChange}
                      id="commissioned_by"
                    />
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.contact,
                      })}
                      placeholder="Contact"
                      value={this.state.contact}
                      onChange={this.onChange}
                      id="contact"
                    />
                  </React.Fragment>
                ) : null}

                {this.state.showNoneForm ? (
                  <React.Fragment>
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.language,
                      })}
                      placeholder="Language (optional)"
                      value={this.state.language}
                      onChange={this.onChange}
                      id="language"
                    />
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.sidecar_by,
                      })}
                      placeholder="Created by"
                      value={this.state.sidecar_by}
                      onChange={this.onChange}
                      id="sidecar_by"
                    />
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.commissioned_by,
                      })}
                      placeholder="Commissioned by"
                      value={this.state.commissioned_by}
                      onChange={this.onChange}
                      id="commissioned_by"
                    />
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.contact,
                      })}
                      placeholder="Contact"
                      value={this.state.contact}
                      onChange={this.onChange}
                      id="contact"
                    />
                  </React.Fragment>
                ) : null}

                <h5 className="mb-4">Additional Information</h5>
                <Row>
                  <Col>
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.certificate,
                      })}
                      placeholder="Certificate (optional)"
                      value={this.state.certificate}
                      onChange={this.onChange}
                      id="certificate"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      className={classnames("input-field", {
                        invalid: errors.frame_rate,
                      })}
                      placeholder="Frame Rate (optional)"
                      value={this.state.frame_rate}
                      onChange={this.onChange}
                      id="frame_rate"
                    />
                  </Col>
                  <Col>
                    <Form.Select
                      className={classnames("input-field", {
                        invalid: errors.quality_level,
                      })}
                      value={this.state.quality_level}
                      onChange={this.onChange}
                      id="quality_level"
                    >
                      <option>Quality Level</option>
                      <option value="Professional">Professional</option>
                      <option value="Non-Professional">Non-Professional</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <button type="submit">
                {auth.user.isTrusted ? "Submit" : "Submit for moderation"}
              </button>
            </Form>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

AddFilm.propTypes = {
  addFilm: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { addFilm })(AddFilm);
