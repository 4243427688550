import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import qs from "query-string";
import axios from "axios";
import ReactLoading from "react-loading";

import Sidebar from "./Sidebar.js";
import EmptySidebar from "./EmptySidebar.js";
import FilmTable from "../table/FilmTable.js";
import { getFilms } from "../../actions/filmActions";

import "./Collections.css";

const query = {};

class Collections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_films: [],
      films: [],
      search: "",
      loading: true,
    };

    axios
      .get("api/films?approved=true")
      .then((res) => {
        this.setState({
          all_films: res.data.data,
          films: res.data.data,
          loading: false,
        });
      })
      .catch((err) => console.log(err));

    this.onFilter = this.onFilter.bind(this);
  }

  onSearchIconClick = (e) => {
    e.preventDefault();
    this.searchInput.focus(); // Set focus to the input field
  };

  onSearchEnter = (e) => {
    if (e.keyCode === 13) {
      // Check if the Enter key was pressed
      e.preventDefault();
      this.onChange(e);
    }
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value });
    this.onFilter(e.target.id, e.target.value, false, true);
  };

  onFilter(key, value, isSelected, isSlider = false) {
    const oldValue = query[key] ? query[key] : null;
    if (isSelected) {
      query[key] = oldValue ? [...oldValue, value] : [value];
    } else if (isSlider) {
      query[key] = value;
    } else {
      const index = query[key].indexOf(value);
      if (index > -1) {
        query[key].splice(index, 1);
      }
    }

    const searchString = qs.stringify(query);
    axios
      .get(`api/films/?approved=true&${searchString}`)
      .then((res) =>
        this.setState({
          films: res.data.data,
        })
      )
      .catch((err) => console.log(err));
  }

  renderFilms() {
    const { films, search, loading } = this.state;
    const isMobile = window.innerWidth < 1000;

    // Mobile and no search - empty
    if (isMobile && search === "") {
      return null; // or return an empty component, e.g., <div></div>
    }

    if (loading) {
      return (
        <div style={{ margin: "20px" }}>
          <ReactLoading type="bubbles" color="#fff" width="100px" />
        </div>
      );
    }

    // Mobile and search - film table with mobile attachment
    if (isMobile && search && films.length > 0) {
      return <FilmTable data={films} isMobile={true} />;
    }

    // Not mobile and is data - normal film table
    if (!isMobile && films.length > 0) {
      return <FilmTable data={films} />;
    }

    // No data - no results found
    return <p style={{ margin: "20px" }}>No results found.</p>;
  }

  render() {
    const { all_films, loading } = this.state;

    return (
      <div className="collections">
        {all_films[0] ? (
          <Sidebar films={all_films} onFilter={this.onFilter} />
        ) : (
          <EmptySidebar loading={loading} />
        )}

        <Container>
          <div style={{ margin: "20px" }}>
            <main>
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  fontSize: "1.2em",
                  margin: "50px 20px 10px 20px",
                }}
              >
                <InputGroup className="search">
                  <Form.Control
                    style={{
                      height: "50px",
                      fontSize: "1.1em",
                      border: "none",
                    }}
                    id="search"
                    placeholder="Search film or director..."
                    onChange={(e) =>
                      this.setState({ [e.target.id]: e.target.value })
                    }
                    onKeyDown={this.onSearchEnter} // Listen for the Enter key press
                    value={this.state.search}
                    onBlur={this.onChange} // Call onChange when the input loses focus
                    ref={(input) => {
                      this.searchInput = input;
                    }} // Add a ref to the input field
                  />
                  <InputGroup.Text
                    style={{
                      background: "white",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <i className="bi bi-search"></i>
                  </InputGroup.Text>
                </InputGroup>
                <Link to="/add" className="desktop-only">
                  <button className="button-blue">Add Film(s)</button>
                </Link>
              </div>
            </main>

            {this.renderFilms()}
          </div>
        </Container>
      </div>
    );
  }
}

Collections.propTypes = {
  getFilms: PropTypes.func.isRequired,
  film: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  film: state.film,
});

export default connect(mapStateToProps, { getFilms })(Collections);
