import React, { Component } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";

export default class Sidebar extends Component {
  render() {
    return (
        <div className="sidebar">
        <ProSidebar>
          <Menu>
            <MenuItem><Link to="/dashboard">Dashboard</Link></MenuItem>
            <MenuItem><Link to="/add">Add film(s)</Link></MenuItem>
            <MenuItem><Link to="/manage-users">Manage users</Link></MenuItem>
          </Menu>
        </ProSidebar>
        </div>
    );
  }
}
