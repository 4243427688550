import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Static.css";

export default class Terms extends Component {
  render() {
    return (
      <div className="middle-body static">
        <h1>Terms of Use</h1>
        <p>
          You should read these terms and conditions carefully before using the
          Sidecard website (referred to as &lsquo;the Site&rsquo;). By accessing
          or using the Site, you agree to be bound by these terms and conditions
          and our <Link to="/privacy-policy">privacy policy</Link>. No proposed
          changes by you to these terms and conditions are valid or have any
          effect. If you do not agree with or accept any of these terms and
          conditions, you should cease using the Site immediately.
        </p>
        <p>
          The Sidecard website is managed by{" "}
          <a href="https://matchboxcine.com/" target="_blank" rel="noopener noreferrer">
            Matchbox Cine
          </a>{" "}
          on behalf of{" "}
          <a href="https://inclusivecinema.org/" target="_blank" rel="noopener noreferrer">
            Inclusive Cinema
          </a>
          .
        </p>
        <p>
          <strong>
            Availability and conditions of using the Sidecard website
          </strong>
        </p>
        <p>
          The Site is made available to you by us. Whilst we make every effort
          to ensure that the Site is available, we do not represent, warrant or
          guarantee in any way the Site&rsquo;s continued availability at all
          times or uninterrupted use by you of the Site. We reserve the right to
          suspend or cease the operation of the Site from time to time at our
          sole discretion.
        </p>
        <p>As a condition of your use of the Site, you agree:&nbsp;</p>
        <ul>
          <li>
            <p>
              not to use the Site for any purpose that is unlawful under
              applicable law, or prohibited by these terms and conditions; and
              you must also use it in a way that doesn&rsquo;t infringe the
              rights of, or restrict or inhibit the use and enjoyment of, this
              site by anyone else.
            </p>
          </li>
          <li>
            <p>
              to be responsible for ensuring that your use of the Site is
              consistent with all applicable laws and regulations.
            </p>
          </li>
        </ul>
        <p>
          We reserve the right to prevent or suspend your access to the Site if
          you do not comply with any part of these terms and conditions or any
          applicable law.
        </p>
        <p>
          <strong>Ownership, use and intellectual property rights</strong>
        </p>
        <p>
          The Site and all content within the Site is owned and operated by us
          and/or our partner organisations. We and our partner organisations
          reserve all rights.
        </p>
        <p>
          The Site is for your personal and non-commercial use. You may not
          modify, copy, distribute, transmit, display, revise, perform,
          reproduce, publish, license, deep-link, create derivative works from,
          transfer, or sell any information or content obtained from the Site
          unless we have given permission for you to do so.
        </p>
        <p>
          <Link to="/contact">Contact us</Link> if you want to reproduce a piece
          of content but aren&rsquo;t sure if you are able to. Any intellectual
          property rights (including without limitation all patents, copyright,
          database rights and trademarks (whether registered or unregistered))
          subsisting in any content or material on the Site belongs to us and/or
          our partner organisations. All rights are reserved for the benefit of
          ourselves and/or our partner organisations. Nothing in these terms and
          conditions grants you any rights in the Site or the content within the
          Site.
        </p>
        <p>
          The use or misuse of any trademarks or any other content on the Site
          except as provided in these terms and conditions or in the Site
          content, is strictly prohibited. Nothing contained on the Site shall
          be construed as granting, by implication, estoppel or otherwise, any
          license or right to use any trademark without our prior written
          permission.
        </p>
        <p>
          <strong>Submissions</strong>
        </p>
        <p>
          We appreciate hearing from our clients and welcome your comments
          regarding our services, including our online services. Our company
          policy does not encourage you to submit or send to us any unpatented
          ideas, advertising or marketing suggestions, patent applications,
          models, prototypes, or any information, written or oral, which you
          regard as confidential (collectively referred to as &lsquo;Unwanted
          Submissions&rsquo;). While we value your feedback on our services, we
          request that you restrict your comments to our services and do not
          submit any Unwanted Submissions.
        </p>
        <p>
          Please note that any submission (including any Unwanted Submission)
          made to us is deemed to be our property and we are entitled to utilise
          any such submission in any manner we see fit. We shall not be subject
          to any obligation of confidentiality nor be liable for any use and/or
          disclosure of such submissions.
        </p>
        <p>
          <strong>Posting of information and job advertisements</strong>
        </p>
        <p>
          Except for any personal information we may collect from you which is
          governed by our <Link to="/privacy-policy">privacy policy</Link> and
          our cookie policy, any communication or material you submit to us or
          the Site is, and will be treated as, non-confidential and
          non-proprietary. By submitting any communication or material, you
          agree that we and any of our affiliates may use your communication for
          any purpose, including reproduction, transmission, publication,
          broadcast, and posting. Although we reserve the right to monitor or
          review postings, transmissions, and the like on the Site from time to
          time, we are under no obligation to do so and assume no responsibility
          or liability arising from any content posted on any of these
          functionalities nor for any error, omission, infringement, defamatory
          statement, obscenity, or inaccuracy contained in any such information
          on such functionalities. We shall have the right at any time and for
          any reason to remove any communication or material posted on the Site.
        </p>
        <p>
          We reserve the right to change the wording or content, or to refuse to
          post on your behalf any job advertisement we receive. We are not
          liable for the content of any job advertisement submitted to us or the
          content of external sites that may be linked from that post. We
          provide this service free of charge and in good faith. Job
          advertisements are not endorsements of particular organisations or
          roles and should not be seen as such.
        </p>
        <p>
          <strong>Requests to remove content</strong>
        </p>
        <p>
          You can ask for content to be removed from the Sidecard website.
          We&rsquo;ll only do this in certain cases, e.g. if it breaches
          copyright laws, contains sensitive personal data or material that may
          be considered obscene or defamatory.
        </p>
        <p>
          Contact us to ask for content to be removed. You&rsquo;ll need to send
          us the web address (URL) of the content and explain why you think it
          should be removed. We&rsquo;ll reply to let you know whether
          we&rsquo;ll remove it.
        </p>
        <p>
          <strong>Disclaimers</strong>
        </p>
        <p>
          While we use reasonable efforts to include accurate and up-to-date
          information on the Site, we do not guarantee &nbsp;that any
          information is or remains accurate, complete and up to date.
        </p>
        <p>
          Nothing in these terms and conditions shall operate to prejudice any
          mandatory statutory requirement or your statutory rights.
        </p>
        <p>
          Content on the Site is provided for your general information purposes
          only and to inform you about us and our news, features, services and
          other websites, which may be of interest. It does not constitute
          technical, financial, or legal advice.
        </p>
        <p>
          We&rsquo;re not responsible for any loss, disruption or damage to your
          data or computer system that might happen when you use the Site.
        </p>
        <p>
          <strong>Hyperlinks and third party sites</strong>
        </p>
        <p>
          The Site may contain hyperlinks or references to external third party
          websites. Any such hyperlinks or reference is provided for your
          convenience only.
        </p>
        <p>
          We have no control over third party websites and accept no
          responsibility for any content, material or information contained in
          them.
        </p>
        <p>
          The display of any hyperlink and reference to any third party website
          does not constitute an endorsement of such third party&rsquo;s
          website, products or services. Your use of a third party site may be
          governed by the terms and conditions of that third party site.
        </p>
        <p>
          <strong>Warranties and limitation of liability</strong>
        </p>
        <p>
          You agree that your use of the Site is on an &lsquo;as is&rsquo; and
          &lsquo;as available&rsquo; basis. Consequently we make no
          representations, warranties, conditions or other terms (whether
          express or implied) in relation to the provision of the Site,
          including without limitation as to completeness, accuracy and currency
          or any content and information on the Site, or as to satisfactory
          quality, or fitness for particular purpose.
        </p>
        <p>
          To the maximum extent permitted by applicable law, we exclude all
          liability (whether arising in contract, tort, breach of statutory duty
          or otherwise) which we may otherwise have to you as a result of:
        </p>
        <ul>
          <li>
            <p>
              any error or inaccuracies in any information or material within or
              relating to the Site;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>the unavailability of the Site for whatsoever reason; and</p>
          </li>
        </ul>
        <ul>
          <li>
            <p>any representation or statement made on the Site.</p>
          </li>
        </ul>
        <p>
          Under no circumstances shall we be liable to you for any loss or
          damage suffered (including without limitation direct or indirect
          losses) arising from your use of, or reliance on, the Site (including
          the downloading or any software, program or information).
        </p>
        <p>
          We do not exclude or limit our liability for death or personal injury
          arising from our negligence, for any fraudulent misrepresentation made
          by us on the Site or for any other statutory rights which are not
          capable of being excluded.
        </p>
        <p>
          <strong>Indemnity</strong>
        </p>
        <p>
          If you are in breach of any of these terms and conditions, you agree
          to indemnify and hold us harmless in respect of any costs, expenses,
          claims, proceedings, actions, losses, damages or liabilities incurred
          by us in relation to or arising from such a breach.
        </p>
        <p>
          <strong>General</strong>
        </p>
        <p>
          These terms and conditions contains the entire understanding and
          agreement between us and you in relation to your use of the Site and
          supersedes and replaces any representation, statement or other
          communication (whether written or otherwise) made by you or us which
          is not contained herein.
        </p>
        <p>
          Should any part of these terms and conditions for any reason be
          declared invalid or unenforceable by a court of a competent
          jurisdiction, it shall be deemed to be deleted without affecting the
          remaining provisions.
        </p>
        <p>
          These terms and conditions are governed and construed in accordance
          with the laws of England and Wales and you consent to the exclusive
          jurisdiction of the courts of England.
        </p>
        <p><strong>Changes to these terms and conditions</strong></p>
        <p>
          We reserve the right to vary these terms and conditions from time to
          time without notifying you. Please check these terms and conditions
          regularly as by continuing to use and access the Site you agree to any
          changes.
        </p>
      </div>
    );
  }
}
