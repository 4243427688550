import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Static.css";

export default class Accessibility extends Component {
  render() {
    return (
      <div className="middle-body static">
        <h1>Accessibility</h1>
        <p>
          Sidecard aims to support the use of access materials for film
          screenings. Sidecard records which files are in existence and who may
          be contacted to make use of them will, by extension, promote
          accessible screenings. Sidecard, as a resource, also hopes to bridge
          the knowledge gap which discourages film-makers, distributors and
          exhibitors to facilitate accessible screenings. Sidecard aims to
          promote the further use of access materials that might otherwise be
          under-utilised, if at all utilised, and also to minimise duplication
          of efforts across the sector.
        </p>
        <p>
          <strong>Website Accessibility</strong>
        </p>
        <p>
          As advocates for access in film exhibition, we want to ensure that our
          website is accessible and inclusive for all users. We have been
          working with access consultants throughout the development process,
          and we will continue to reassess Sidecard&rsquo;s digital access and
          improve as necessary. If you have any feedback or questions, do
          contact us at&nbsp;
          <Link
            to="#"
            onClick={() => window.open("mailto:hello@sidecard.co.uk")}
            target="_blank" rel="noopener noreferrer"
          >
            hello@sidecard.co.uk
          </Link>{" "}
          or 0758 848 9947.
        </p>
        <p>
          <strong>Userway Accessibility Widget</strong>
        </p>
        <p>
          Userway&rsquo;s accessibility widget can be found in the top
          right-hand corner of any page of the Sidecard website. Userway&rsquo;s
          widget has a suite of 100+ AI-powered accessibility functions and is
          WCAG 2.1 AA, ADA, Section 508 compliant.
        </p>
        <p>
          <strong>WCAG (Web Content Accessibility Guidelines)</strong>
        </p>
      </div>
    );
  }
}
