import React, { Component } from "react";
import Container from "react-bootstrap/esm/Container";

export default class EmailSuccess extends Component {
  render() {
    return (
      <Container className="middle-body">
        <h1>Email sent</h1>
        <p>An email has been sent to you with reset instructions.</p>
        <p>Haven't received it? Try checking your junk folder.</p>
        <p>
          If you haven't received an email after an hour, contact
          hello@sidecard.co.uk for assistance.
        </p>
      </Container>
    );
  }
}
