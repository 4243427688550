import React, { Component } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import matchbox from "../../assets/matchbox.png";
import fhw from "../../assets/fhw.png";
import fhs from "../../assets/fhs.png";
import ic from "../../assets/ic.png";
import ico from "../../assets/ico.png";
import bfi from "../../assets/bfi.png";

import "./Layout.css";

export default class Footer extends Component {
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <footer>
        <div className="footer">
          <Container>
            <Row
              style={{ padding: "40px 0px", justifyContent: "space-between" }}
            >
              <Col className="image-row">
                <div
                  className="d-flex p-3"
                  style={{ padding: "40px 0px", gap: "10px" }}
                >
                  <a
                    href="https://matchboxcineclub.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ minHeight: "55px" }}
                      src={matchbox}
                      alt="Matchbox Cinema Logo"
                    />
                  </a>
                  <a
                    href="https://filmhubwales.org/en/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ minHeight: "55px" }}
                      src={fhw}
                      alt="Film Hub Wales Logo"
                    />
                  </a>
                  <a
                    href="https://inclusivecinema.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ minHeight: "65px" }}
                      src={ic}
                      alt="Inclusive Cinema Logo"
                    />
                  </a>
                  <a
                    href="https://www.filmhubscotland.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ minHeight: "50px" }}
                      src={fhs}
                      alt="Film Hub Scotland Logo"
                    />
                  </a>
                  <a
                    href="https://www.independentcinemaoffice.org.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ minHeight: "50px" }}
                      src={ico}
                      alt="Independent Cinema Office Logo"
                    />
                  </a>
                  <a
                    href="https://www.bfi.org.uk/get-funding-support/bring-film-wider-audience/bfi-film-audience-network"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ minHeight: "50px" }}
                      src={bfi}
                      alt="BFI Film Audience Network / National Lottery Logo"
                    />
                  </a>
                </div>
              </Col>
              <Col className="footer-links">
                <h6>
                  <Link to="/accessibility" onClick={() => this.scrollToTop()}>Accessibility</Link>
                </h6>
                <h6>
                  <Link to="/terms-of-use" onClick={() => this.scrollToTop()}>Terms of Use</Link>
                </h6>
                <h6>
                  <Link to="/privacy-policy" onClick={() => this.scrollToTop()}>Privacy Policy</Link>
                </h6>
                <h6>
                  <Link to="/contact" onClick={() => this.scrollToTop()}>Contact</Link>
                </h6>
              </Col>
              <Col style={{ maxWidth: "40%" }}>
                <h6>© Matchbox Cine 2023</h6>
                <h6>
                  <a
                    href="https://zarasiddique.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Site created by Zara Siddique
                  </a>
                </h6>
                <h4>
                <a
                    href="https://www.twitter.com/cinesidecard/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ paddingRight: "5px"}}
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://www.facebook.com/cinesidecard/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ paddingRight: "5px"}}
                  >
                    <FaFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/cinesidecard/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ paddingRight: "5px"}}
                  >
                    <FaInstagram />
                  </a>
                </h4>

              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}
