import React, { Component } from "react";
import FilmRow from "./FilmRow.js";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { BiSortAlt2 } from "react-icons/bi";
let _ = require("underscore");

export default class FilmTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allChecked: false,
      films: props.data,
      currentSort: "",
      order: false,
      amountShown: this.props.isMobile ? 6 : 30,
      amountOfFilms: props.data.length,
    };

    this.showMoreFilms = this.showMoreFilms.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        films: nextProps.data,
        amountOfFilms: nextProps.data.length,
      });
    }
  }

  onCheckAll() {
    this.setState({ allChecked: !this.state.allChecked });
  }

  renderRows(allChecked, amountShown) {
    // check slice is smaller than list size
    return this.state.films
      .slice(0, amountShown)
      .map((film) =>
        film.directors ? (
          <FilmRow
            allChecked={allChecked}
            isDashboard={this.props.isDashboard}
            isProfile={this.props.isProfile}
            key={film._id}
            onSelect={this.props.onSelect}
            film={film}
          />
        ) : null
      );
  }

  onClick(typeOfSort) {
    var order = true;
    if (typeOfSort === this.state.currentSort) {
      order = this.state.order;
    }

    const films = this.state.films;
    var sorted_films = _.sortBy(films, typeOfSort);

    if (!order) {
      sorted_films = _.sortBy(films, typeOfSort).reverse();
    }

    this.setState({
      films: sorted_films,
      currentSort: typeOfSort,
      order: !order,
    });
  }

  showMoreFilms(reset) {
    // If show less is clicked, back to 80
    if (reset) {
      this.setState({ amountShown: this.props.isMobile ? 5 : 30, showMoreButton: true });
    }
    // If show more is clicked
    else {
      this.setState({ amountShown: this.state.amountShown + 20 });
    }
  }

  render() {
    const { isDashboard, isProfile } = this.props;
    const { allChecked } = this.state;

    return (
      <React.Fragment>
        <div className="table" style={ isProfile ? {padding: "0"} : null}>
          <Table responsive striped hover style={{ backgroundColor: "white" }}>
            <thead>
              <tr>
                {isDashboard ? (
                  <React.Fragment>
                    <th>
                      <Form.Check onChange={() => this.onCheckAll()} />
                    </th>
                    <th> </th>
                  </React.Fragment>
                ) : null}
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => this.onClick("title")}
                >
                  <BiSortAlt2 /> Title
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => this.onClick("directors")}
                >
                  <BiSortAlt2 /> Director/s
                </th>
                <th
                  className="desktop-only"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.onClick("year")}
                >
                  <BiSortAlt2 /> Year
                </th>
                <th className="desktop-only">Category</th>
                {isDashboard ? (
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => this.onClick("created_at")}
                  >
                    <BiSortAlt2 /> Submitted
                  </th>
                ) : null}
                {isDashboard || isProfile ? <th>Status</th> : null}
                <th> </th>
              </tr>
            </thead>
            <tbody>{this.renderRows(allChecked, this.state.amountShown)}</tbody>
          </Table>
        </div>
        <div className="table d-flex justify-content-center">
          {this.state.amountOfFilms < 30 ? null : this.state.amountOfFilms >
            this.state.amountShown ? (
            <button onClick={() => this.showMoreFilms(false)}>
              Show More Films
            </button>
          ) : (
            <button onClick={() => this.showMoreFilms(true)}>
              Show Less Films
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}
