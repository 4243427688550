import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

import "./Static.css";

export default class GlossaryOne extends Component {
  render() {
    return (
      <div className="glossary">
        <h1>General Glossary</h1>

        <p>
        The General Glossary lists general terminology, but the most important
        for using Sidecard are Descriptive Subtitles (DS) and Audio Description
        (AD).
          We recommend working with an experienced access practitioner, who can
          assist you to identify and manage any and all materials related to
          access provision.
        </p>
        <p>
          Please note, the glossary is expansive but not exhaustive. If you find
          anything missing, or would like to query or clarify any of the
          definitions, please get in touch:{" "}
          <Link
            to="#"
            onClick={() => window.open("mailto:hello@sidecard.co.uk")}
            target="_blank"
            rel="noopener noreferrer"
          >
            hello@sidecard.co.uk
          </Link>
          .
        </p>
        <Table responsive striped hover style={{ backgroundColor: "white" }}>
          <tbody>
            <tr>
              <th>Word/Phrase</th>
              <th>Definition</th>
            </tr>
            <tr>
              <td>Aligning</td>
              <td>
                The process of adding timecodes to an untimed transcript to sync
                it with an associated film or video, in order to create subtitle
                files. Text is broken down into chunks dictated by sync,
                character limitations (typically two lines and between 32-42
                characters per line) and reading speed.
              </td>
            </tr>
            <tr>
              <td>Audio Description (AD)</td>
              <td>
                Audio files, complementing a film's soundtrack, which describe
                dramatically-relevant visual information, including visual
                descriptions of location and characters; the physical action of
                characters and other elements; and any necessary on-screen text.
              </td>
            </tr>
            <tr>
              <td>Auto-generated Subtitles</td>
              <td>See also: Automatic Speech Recognition (ASR)</td>
            </tr>
            <tr>
              <td>Automatic Speech Recognition (ASR)</td>
              <td>See: Auto-generated Subtitles</td>
            </tr>
            <tr>
              <td>BITC</td>
              <td>
                "Burned-in Timecode" A running clock hardcoded into the film
                image, used in post-production, particularly useful when
                creating Audio Description (AD)
              </td>
            </tr>
            <tr>
              <td>Burned-in Subtitles</td>
              <td>
                Subtitles which are a permanent/irremovable part of the film
                image. See also: Open Captions; Hardcoded Subtitles
              </td>
            </tr>
            <tr>
              <td>Captions</td>
              <td>See also: Descriptive Subtitles (DS)</td>
            </tr>
            <tr>
              <td>CCAP</td>
              <td>
                "Closed Captions", as used in DCP naming conventions, in the
                language field. See also: DCP; Closed Captions
              </td>
            </tr>
            <tr>
              <td>CCSL</td>
              <td>
                "Combined Continuity, Dialogue, and Spotting List" a document
                created in post-production, containing timecodes, graphics,
                music cues, all spoken dialogue along with speech identifiers
                (e.g. Han, Luke Leia) and descriptive elements to aid the
                creation of subtitle files, particularly in translation. See
                also: CDSL
              </td>
            </tr>
            <tr>
              <td>CDSL</td>
              <td>"Combined Dialogue and Spotting List" See also: CCSL</td>
            </tr>
            <tr>
              <td>Clean Video</td>
              <td>
                When a video file has no burned-in subtitles, it's clean. NB
                sometimes this term is applied to videos with no text of any
                kind on-screen - i.e. graphics and even credit rolls may be
                absent.
              </td>
            </tr>
            <tr>
              <td>Closed Captions</td>
              <td>
                Optional subtitle files. Closed captions are text displayed to
                assist hearing impaired audience members. Captions' text
                includes scene dialogue and descriptions of key sounds i.e.
                "phone rings" or "door slams". Closed captions are generally
                presented off-screen on dedicated devices and are not generally
                visible to the entire audience. See also: Captions, Open
                Captions
              </td>
            </tr>
            <tr>
              <td>DCP</td>
              <td>
                Digital Cinema Package; A collection of digital files, the
                standard delivery format for digital cinema. Broadly the digital
                equivalent of a 35mm print
              </td>
            </tr>
            <tr>
              <td>Described Video (DV)</td>
              <td>See: Audio Description (AD)</td>
            </tr>
            <tr>
              <td>Descriptive Subtitles (DS)</td>
              <td>
                Subtitles incorporating all of a film's dialogue with
                descriptive elements (e.g. sound and music labels and speech
                identifiers). Primarily used by Deaf audiences for access
                purposes, descriptive subtitles are also widely used by
                neuro-divergent viewers, people for whom English is their second
                language, and increasingly by fully hearing, native English
                speakers in various contexts. Descriptive subtitles are also
                known as captions, SDH, HoH.
              </td>
            </tr>
            <tr>
              <td>Dialogue list</td>
              <td>
                A timecoded post-production script. Often used as the basis for
                Spotting Lists/Subtitles and dubbing. See also: Script; Timecode
              </td>
            </tr>
            <tr>
              <td>Diegetic / Non-diegetic</td>
              <td>
                The 'world' of a moving image text, as indicated not only by
                what can be seen, or by sounds generated from on-screen actions
                and objects (e.g. footsteps, explosions), but also by off-screen
                sounds that belong to the world being depicted (e.g. birdsong,
                church bells). Non-diegetic sound is typically music or sound
                effects not generated in the filmic world but added to indicate
                characters' state of mind or to generate audience response.
              </td>
            </tr>
            <tr>
              <td>Digital Video Broadcasting (DVB)</td>
              <td></td>
            </tr>
            <tr>
              <td>Dubbing</td>
              <td>
                The practice of adding a soundtrack to pre-existing film. The
                term is most often used in connection with replacing a film's
                original dialogue with a translation into a different language,
                with the recording of the original actors being replaced by
                alternative vocal performances by native speakers of the new
                language. Although extremely common in Europe (particularly
                Italy, Spain, Germany and France), dubbing is not very popular
                in English-speaking countries, and is generally restricted to
                the exploitation end of the market (especially horror and
                martial arts), as most British audiences for foreign films
                prefer them to be in the original language with subtitles.
              </td>
            </tr>
            <tr>
              <td>Embedded Subtitles</td>
              <td>
                Subtitle files that are muxed into video files, but are optional
                as opposed to burned-in/hardcoded
              </td>
            </tr>
            <tr>
              <td>Forced Narrative (FN)</td>
              <td>
                Text overlaid onto video used to convey important/necessary
                information, e.g. to transcribe signs or other on-screen text,
                or to identify people or places
              </td>
            </tr>
            <tr>
              <td>FPS</td>
              <td>"Frames-per-second" See also: Frame rate; Frame</td>
            </tr>
            <tr>
              <td>Frame</td>
              <td>
                Individual still image of a film or video, or the rectangle
                within which the image is composed or captured. See also: Frame
                rate; FPS
              </td>
            </tr>
            <tr>
              <td>Frame rate</td>
              <td>
                The number of still images that pass through the
                camera/projector per second. Film usually runs at 24fps, video
                at 25fps. See also: FPS; Frame
              </td>
            </tr>
            <tr>
              <td>Hard of Hearing (HoH)</td>
              <td>See: Descriptive Subtitles (DS)</td>
            </tr>
            <tr>
              <td>Hardcoded Subtitles</td>
              <td>
                Subtitles which are a permanent/irremovable part of the film
                image. See also: Open Captions; Hardcoded Subtitles
              </td>
            </tr>
            <tr>
              <td>Interop</td>
              <td>
                From "Interoperable"; Standard for DCP production. See also:
                SMPTE; DCP
              </td>
            </tr>
            <tr>
              <td>Intertitles</td>
              <td>
                Titles that appear on screen between moving image material.
                Intertitles are most commonly seen in silent films, where they
                act as substitutes for dialogue, though they can also be used as
                a kind of 'chapter heading'
              </td>
            </tr>
            <tr>
              <td>Music Cue Sheet</td>
              <td>
                A document included in the deliverable materials of a film or
                television show. Contains a list of all the music cues and songs
                used in a film, typically including duration, composer and
                publisher details. Useful in the creation of descriptive
                subtitle files, as a reference and/or a guide for research.
              </td>
            </tr>
            <tr>
              <td>Muxed</td>
              <td>
                Short for "multiplexed", meaning the combination of audio files
                and video files into one "container", sometimes also containing
                embedded subtitle files
              </td>
            </tr>
            <tr>
              <td>Naming convention</td>
              <td>
                Standard form for naming and categorising access materials
                files, so that they are easily filed, their content is
                understood and the files are correctly matched to the video they
                were made against. Sidecard recommends the naming convention
                "Film Name_language_type_frame rate.file format" e.g.
                "Apocalypse Now_eng_SDH_25fps.srt"
              </td>
            </tr>
            <tr>
              <td>OCAP</td>
              <td>
                "Open Captions", as used in DCP naming conventions, in the
                language field. See also: DCP; Open Captions
              </td>
            </tr>
            <tr>
              <td>OCR</td>
              <td>
                "Optical character recognition", a process used to extract
                text-based subtitle files from image-based files, or from
                burned-in videos
              </td>
            </tr>
            <tr>
              <td>Offset</td>
              <td>
                The process of shifting the timecodes of an entire file, or
                sections of a file, en masse, in order to sync with a film or
                video. Typically employed when access materials for one
                version/cut/edit of a video are adapted for use with another.
              </td>
            </tr>
            <tr>
              <td>Open Captions</td>
              <td>
                Subtitles which are on-screen throughout, by default. Open
                captions are text displayed to assist hearing impaired audience
                members. Captions' text includes scene dialogue and descriptions
                of key sounds i.e. "phone rings" or "door slams". Open captions
                are rendered by the server or projector and will display the
                timed text captions on screen, visible to the entire audience.
                See also: Hardcoded Subtitles; Burned-in Subtitles; Captions
              </td>
            </tr>
            <tr>
              <td>OpTrans</td>
              <td>See: Surtitles</td>
            </tr>
            <tr>
              <td>OV</td>
              <td>"Original Version"</td>
            </tr>
            <tr>
              <td>Script</td>
              <td>
                Screenplay for movie or television show, in various
                forms/iterations, including shooting script and post-production
                script. Any and all can be useful as a reference when creating
                access materials.
              </td>
            </tr>
            <tr>
              <td>Shot-change</td>
              <td>
                The point marking the transition between one shot and another,
                whether it's between scenes, different locations or simply
                angles within a scene. Quality subtitle files acknowledge and
                negotiate shotchanges to various degrees in order to ensure a
                smooth, immersive experience for the viewer.
              </td>
            </tr>
            <tr>
              <td>SMPTE</td>
              <td>
                "Society of Motion Picture and Television Engineers"; Standard
                for DCP production, mostly superceded by INTEROP. See also:
                INTEROP; DCP
              </td>
            </tr>
            <tr>
              <td>Soft titles</td>
              <td>See: Closed captions</td>
            </tr>
            <tr>
              <td>Sound labels</td>
              <td>
                Descriptive text labels used in subtitle files to relate
                dramatically relevant non-dialogue elements in the soundtrack,
                eg. [Door slams], [She screams], [Classical music plays]
              </td>
            </tr>
            <tr>
              <td>Soundtrack</td>
              <td>
                The audio components of a film - dialogue, sound effects, music.{" "}
              </td>
            </tr>
            <tr>
              <td>Speech identifiers</td>
              <td>
                Descriptive text labels used in conjunction with dialogue in
                subtitle files in order to identify speakers, e.g. [Narrator],
                [Mother], [Colonel Kurtz]
              </td>
            </tr>
            <tr>
              <td>Spotting</td>
              <td>See also: Sync</td>
            </tr>
            <tr>
              <td>Spotting List</td>
              <td>
                A detailed, timed transcript of all dialogue, onscreen text and
                graphics (including signs, written or printed text, etc). Used
                as a basis for translation. See also: Dialogue List; Subtitles;
                Forced Narrative
              </td>
            </tr>
            <tr>
              <td>Subtitles</td>
              <td>
                The presentation of text in the lower part of the screen,
                usually one or two lines at a time. Subtitles can have a variety
                of functions, though they are most commonly used to translate
                foreign-language dialogue in such a way that permits audiences
                to hear the original as well (something not offered by a more
                destructive process such as dubbing), and to help Deaf and
                hard-of-hearing people follow the film or programme by
                presenting a simultaneous written transcription.
              </td>
            </tr>
            <tr>
              <td>Subtitles for the Deaf and Hard of Hearing (SDH)</td>
              <td>See: Descriptive Subtitles (DS); Subtitles; Captions</td>
            </tr>
            <tr>
              <td>supertitle</td>
              <td>See: Surtitles</td>
            </tr>
            <tr>
              <td>SurCaps</td>
              <td>See: Surtitles</td>
            </tr>
            <tr>
              <td>Surtitles</td>
              <td>
                Text files typically used in opera and live theatre to
                transcribe and/or translate lyrics and dialogue. These are
                usually displayed on a separate, dedicated screen, often above a
                stage. See also: Supertitle; SurCaps; OpTrans
              </td>
            </tr>
            <tr>
              <td>Sync</td>
              <td>
                The temporal relationship between the film and the access
                materials - a file may be out of sync if it has the wrong
                timecode or the wrong frame rate, or both. See also: Offset;
                Timecode; Frame Rate
              </td>
            </tr>
            <tr>
              <td>Timecode</td>
              <td>
                Numeric reference (hours/minutes/seconds/frames) for each frame
                of the film, essential during editing and other post-production
                work.
              </td>
            </tr>
            <tr>
              <td>Timed transcript</td>
              <td>
                A document containing only the dialogue of a film, with rough
                timings, usually at regular intervals.
              </td>
            </tr>
            <tr>
              <td>Transcript</td>
              <td>
                A document containing only the dialogue of a film, typically
                with no timings, no accessible elements nor any extraneous
                information. See also: Timed transcript
              </td>
            </tr>
            <tr>
              <td>VF</td>
              <td>"Version File" or "Versioning File"</td>
            </tr>
            <tr>
              <td>VI</td>
              <td>"Visually impaired"</td>
            </tr>
            <tr>
              <td>VI-N</td>
              <td>
                "Visually Impaired Narrative" A narrative audio track for the
                visually impaired: narrative description of content on screen.
                See also: Audio Description (AD)
              </td>
            </tr>
            <tr>
              <td>VLC</td>
              <td>
                "VideoLAN Client" A free and open-source media multimedia player
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
