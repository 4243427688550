import React, { Component } from "react";
import classnames from "classnames";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import { connect } from "react-redux";
import { resetPassword } from "../../actions/authActions";
import PropTypes from "prop-types";

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password2: "",
      token: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.user.isAdmin) {
      this.props.history.push("/dashboard");
    } else if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
    // Set token
    if (this.props.match.params.token) {
      this.setState({ token: this.props.match.params.token });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.user.isAdmin) {
      this.props.history.push("/dashboard");
    } else if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/");
    } else if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };


  onSubmit = (e) => {
    e.preventDefault();
    const data = {
      token: this.state.token,
      password: this.state.password,
      password2: this.state.password2
    }
    this.props.resetPassword(data, this.props.history);
  };


  render() {
    const { errors } = this.state;

    return (
      <Container className="middle-body auth">
        <h1>Reset Your Password</h1>
        <p>Type your new password below.</p>
        <form noValidate onSubmit={this.onSubmit} style={{ maxWidth: "500px" }}>
          <div>
            <p className="red-text">{errors.token}</p>
            <span className="red-text">{errors.password}</span>
            <Form.Control
              onChange={this.onChange}
              value={this.state.password}
              error={errors.password}
              id="password"
              type="password"
              placeholder="Password"
              className={classnames("input-field", {
                invalid: errors.password,
              })}
            />
            <span className="red-text">{errors.password2}</span>
            <Form.Control
              onChange={this.onChange}
              value={this.state.password2}
              error={errors.password2}
              id="password2"
              type="password"
              placeholder="Confirm Password"
              className={classnames("input-field", {
                invalid: errors.password2,
              })}
            />
            <button style={{ width: "100%" }} type="submit">
              Reset Password
            </button>
          </div>
        </form>
      </Container>
    );
  }
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
