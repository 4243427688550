import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

import "./Static.css";

export default class GlossaryTwo extends Component {
  render() {
    return (
      <div className="glossary">
        <h1>File Format Glossary</h1>
        <p>
          The File Format Glossary lists the various file formats you may have
          to deal with in creating and/or managing access provision. The most
          common formats for access materials are .SRT, .VTT., .XML and .WAV. We
          recommend working with an experienced access practitioner, who can
          assist you to identify and manage any and all materials related to
          access provision.
        </p>
        <p>
          Please note, the glossary is expansive but not exhaustive. If you find
          anything missing, or would like to query or clarify any of the
          definitions, please get in touch:{" "}
          <Link
            to="#"
            onClick={() => window.open("mailto:hello@sidecard.co.uk")}
            target="_blank"
            rel="noopener noreferrer"
          >
            hello@sidecard.co.uk
          </Link>
          .
        </p>

        <Table responsive striped hover style={{ backgroundColor: "white" }}>
          <tbody>
            <tr>
              <th>Word/Phrase</th>
              <th>Definition</th>
            </tr>
            <tr>
              <td>.890</td>
              <td>Subtitle file format (associated with Cavena software)</td>
            </tr>
            <tr>
              <td>.ADBE</td>
              <td>Subtitle file format (associated with Adobe products)</td>
            </tr>
            <tr>
              <td>.AFF</td>
              <td>Subtitle file format (associated with Avid products)</td>
            </tr>
            <tr>
              <td>.ASC</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>.ASC</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>.ASS</td>
              <td>
                "Advanced SubStation Alpha", Subtitle file format. See also:
                .SSA
              </td>
            </tr>
            <tr>
              <td>.CAP</td>
              <td>
                "Videotron Lambda", Subtitle file format (primarily used for
                Japanese, Korean and Chinese language subtitles)
              </td>
            </tr>
            <tr>
              <td>.CAP</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>.CCA</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>.CCA</td>
              <td>"MacCaption", Subtitle file format</td>
            </tr>
            <tr>
              <td>.CIN</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>.CIP</td>
              <td>Subtitle file format (associated with Cavena software)</td>
            </tr>
            <tr>
              <td>.CPT.XML</td>
              <td>"Captionate XML", subtitle file format</td>
            </tr>
            <tr>
              <td>.DFXP</td>
              <td>
                "Distribution Format Exchange Profile", Subtitle file format
              </td>
            </tr>
            <tr>
              <td>.IDX</td>
              <td>
                Subtitle file format. A file containing timings, used in
                conjunction with a set of image-based subtitles. See also: .SUB;
                .IDX
              </td>
            </tr>
            <tr>
              <td>.ITT</td>
              <td>
                "iTunes Timed Text", Subtitle file format (associated with
                iTunes)
              </td>
            </tr>
            <tr>
              <td>.JSON</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>.LRC</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>.LRC</td>
              <td>
                Subtitle file format. No styling, but enhanced format supported
              </td>
            </tr>
            <tr>
              <td>.MCC</td>
              <td>MacCaption (MCC V2), Subtitle file format</td>
            </tr>
            <tr>
              <td>.MKS</td>
              <td>"Matroska subtitles", subtitle file format. See also: MKV</td>
            </tr>
            <tr>
              <td>.MKV</td>
              <td>
                "Matroska video", Video file format, free and open, for storing
                multimedia content - audio, video, image and subtitle files.
              </td>
            </tr>
            <tr>
              <td>.MOV</td>
              <td>Video file format (associated with QuickTime)</td>
            </tr>
            <tr>
              <td>.MP4</td>
              <td>Video file format</td>
            </tr>
            <tr>
              <td>.MPSUB</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>.PPT.XML</td>
              <td>Powerpoint XML; Subtitle file format</td>
            </tr>
            <tr>
              <td>.QT</td>
              <td>
                "QuickTime"; Subtitle file format (associated with QuickTime)
              </td>
            </tr>
            <tr>
              <td>.RT</td>
              <td>"RealText", Subtitle file format</td>
            </tr>
            <tr>
              <td>.RT</td>
              <td>RealText, Subtitle file format</td>
            </tr>
            <tr>
              <td>.SAMI</td>
              <td>
                "Synchronized Accessible Media Interchange", Subtitle file
                format. See also: .SMI
              </td>
            </tr>
            <tr>
              <td>.SBV</td>
              <td>
                "SubViewer",Subtitle file format (associated with YouTube). See
                also: .SUB
              </td>
            </tr>
            <tr>
              <td>.SCC</td>
              <td>
                "Scenarist Closed Caption", Subtitle file format (associated
                with North American broadcast television)
              </td>
            </tr>
            <tr>
              <td>.SMI</td>
              <td>
                "Synchronized Accessible Media Interchange", Subtitle file
                format. See also: .SAMI
              </td>
            </tr>
            <tr>
              <td>.SRT</td>
              <td>
                "SubRip Subtitle", Subtitle file format. The most basic,
                universally used and supported subtitle format.
              </td>
            </tr>
            <tr>
              <td>.SSA</td>
              <td>"SubStation Alpha", Subtitle file format. See also: .SSA</td>
            </tr>
            <tr>
              <td>.STL</td>
              <td>
                "EBU-stl", "Spruce Subtitle File", Subtitle file format.
                European Broadcasting Union standard. See also: EBU Stl
              </td>
            </tr>
            <tr>
              <td>.SUB</td>
              <td>
                "MicroDVD", Subtitle file format. Name derived from the MicroDVD
                Player
              </td>
            </tr>
            <tr>
              <td>.SUP</td>
              <td>
                "Subtitle Presentation", Subtitle file format. Graphics-based
                subtitles, essentially a sequence of pictures of subtitles
              </td>
            </tr>
            <tr>
              <td>.TDS</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>.TTML</td>
              <td>
                "Timed Text Markup Language", Subtitle file format. See also:
                .TTML2
              </td>
            </tr>
            <tr>
              <td>.TTML2</td>
              <td>
                "Timed Text Markup Language", Subtitle file format. See also:
                .TTML
              </td>
            </tr>
            <tr>
              <td>.TXT</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>.VTT</td>
              <td>
                "WebVTT", "Web Video Text Track", Subtitle file format (favoured
                by Vimeo, YouTube)
              </td>
            </tr>
            <tr>
              <td>.w32</td>
              <td>Subtitle file format (associated with Wincaps software)</td>
            </tr>
            <tr>
              <td>.WAV</td>
              <td>Audio format</td>
            </tr>
            <tr>
              <td>.WMP.TXT</td>
              <td>"Windows Media timed text file", Subtitle file format</td>
            </tr>
            <tr>
              <td>.XML</td>
              <td>
                "Extensible Markup Language"; Subtitle file format, typically
                used in creation of DCPs
              </td>
            </tr>
            <tr>
              <td>Apple XML</td>
              <td>Apple XML Interchange Format</td>
            </tr>
            <tr>
              <td>Avid DS</td>
              <td>Subtitle file format (associated with Avid products)</td>
            </tr>
            <tr>
              <td>Crackle TT</td>
              <td>Crackle Timed Text (variant of SMPTE-TT)</td>
            </tr>
            <tr>
              <td>DECE CFF</td>
              <td>Variant of SMPTE-TT with auxiliary PNG files</td>
            </tr>
            <tr>
              <td>EBU Stl</td>
              <td>
                "European Broadcasting Union Subtitles", subtitle format. See
                also: .STL
              </td>
            </tr>
            <tr>
              <td>Evertz ProCAP</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>h.264</td>
              <td>
                A video format, the most commonly used for recording,
                compression, and distribution of video content
              </td>
            </tr>
            <tr>
              <td>Matrox for MX02 (Matrox4VANC)</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>Multiple CC (Multiplexed SCC)</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>ONL - CPC 715</td>
              <td>Subtitle file format</td>
            </tr>
            <tr>
              <td>Presentation Graphic Stream Subtitle (PGS)</td>
              <td>
                Subtitle file format. A timed sequence of images, used in
                Blu-Rays. See also: .SUP; .SUB
              </td>
            </tr>
            <tr>
              <td>ProRes</td>
              <td>
                A high-quality video format developed by Apple, used as the
                final delivery method for film files when producing DCPs. See
                also: DCPs
              </td>
            </tr>
            <tr>
              <td>SMPTE-TT</td>
              <td>
                "Society of Motion Picture and Television Engineering - Timed
                Text"
              </td>
            </tr>
            <tr>
              <td>SonyPictures TT</td>
              <td>"Sony Pictures Timed Text XML"</td>
            </tr>
            <tr>
              <td>TIDLP Cinema</td>
              <td>"Texas Instruments DLP Cinema" XML</td>
            </tr>
            <tr>
              <td>VobSub</td>
              <td>Subtitle file format. See also: .SUB; .IDX</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
