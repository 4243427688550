import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import downarrow from "../../assets/downarrow.png";
import uparrow from "../../assets/uparrow.png";
import { connect } from "react-redux";
import { deleteFilm } from "../../actions/filmActions";
import { withRouter } from "react-router";
import { FaFlag } from "react-icons/fa";
import moment from "moment";
import "./Table.css";

class FilmRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seeMore: false,
      isChecked: false,
      allChecked: false,
      confirmDelete: false,
    };

    this.toggle = this.toggle.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.allChecked !== state.allChecked) {
      //Change in props
      props.onSelect(props.film, props.allChecked);
      return {
        isChecked: props.allChecked,
        allChecked: props.allChecked,
      };
    }
    return null; // No change to state
  }

  onCheck() {
    this.setState((previousState) => ({
      isChecked: !previousState.isChecked,
    }));
  }

  onOpenClick() {
    this.setState({ seeMore: true });
  }

  onCloseClick() {
    this.setState({ seeMore: false });
  }

  toggle() {
    this.setState({ confirmDelete: !this.state.confirmDelete });
  }

  onDelete() {
    this.props.deleteFilm(this.props.film._id);
    this.toggle();
  }

  render() {
    const { film, isDashboard, isProfile } = this.props;
    const { confirmDelete } = this.state;

    return (
      <React.Fragment>
        <tr key={film._id} style={{ cursor: "pointer" }}>
          {isDashboard ? (
            <React.Fragment>
              <td
                onClick={() =>
                  !this.state.seeMore ? this.onCloseClick() : this.onOpenClick()
                }
              >
                <Form.Check
                  value={film}
                  onChange={() => {
                    this.onCheck();
                    this.props.onSelect(film, !this.state.isChecked);
                  }}
                  checked={this.state.isChecked}
                />
              </td>
              <td>{film.flagged ? <FaFlag /> : " "}</td>
            </React.Fragment>
          ) : null}
          <td
            onClick={() =>
              !this.state.seeMore ? this.onOpenClick() : this.onCloseClick()
            }
          >
            {film.title}
          </td>
          <td
            onClick={() =>
              !this.state.seeMore ? this.onOpenClick() : this.onCloseClick()
            }
          >
            {film.directors.join(", ")}
          </td>
          <td
            className="desktop-only"
            onClick={() =>
              !this.state.seeMore ? this.onOpenClick() : this.onCloseClick()
            }
          >
            {film.year}
          </td>
          <td
            className="desktop-only"
            onClick={() =>
              !this.state.seeMore ? this.onOpenClick() : this.onCloseClick()
            }
          >
            {film.format}
          </td>
          {isDashboard ? (
            <td
              onClick={() =>
                !this.state.seeMore ? this.onOpenClick() : this.onCloseClick()
              }
            >
              {film.created_at ? moment(film.created_at).format("ll") : null}
            </td>
          ) : null}
          {isDashboard || isProfile ? (
            <td
              onClick={() =>
                !this.state.seeMore ? this.onOpenClick() : this.onCloseClick()
              }
            >
              {film.approved ? (
                <span style={{ marginBottom: "0", color: "#1B5014" }}>
                  Approved
                </span>
              ) : (
                <span style={{ marginBottom: "0", color: "#892727" }}>
                  Draft
                </span>
              )}
            </td>
          ) : null}
          {!this.state.seeMore ? (
            <td onClick={() => this.onOpenClick()}>
              <img src={downarrow} alt="See More" />
            </td>
          ) : (
            <td onClick={() => this.onCloseClick()}>
              <img src={uparrow} alt="See Less" />
            </td>
          )}
        </tr>
        {this.state.seeMore ? (
          <tr>
            <td colSpan={isDashboard ? 9 : isProfile ? 8 : 5}>
              <Row className="film-data">
                <Col>
                  <p>Type: {film.sidecar_type}</p>
                  <p>File Format: {film.sidecar_format}</p>
                  <p>Language: {film.language}</p>
                  <p>Created by: {film.sidecar_by}</p>
                  <p>Commissioned by: {film.commissioned_by}</p>
                  <p>
                    Contact: <strong>{film.contact}</strong>
                  </p>
                </Col>
                <Col>
                  <p className="mobile-only">Year: {film.year}</p>
                  <p>
                    Duration:{" "}
                    {film.duration ? film.duration + " mins" : "Unknown"}
                  </p>
                  <p>Frame rate: {film.frame_rate}</p>
                  {film.certificate ? (
                    <p>Certificate: {film.certificate}</p>
                  ) : null}
                  {film.quality_level ? (
                    <p>Quality Level: {film.quality_level}</p>
                  ) : null}
                  {film.genres && film.genres.length !== 0 ? (
                    <p>Genres: {film.genres.join(", ")}</p>
                  ) : null}
                  {film.alt_titles && film.alt_titles.length !== 0 ? (
                    <p>Alternative titles: {film.alt_titles.join(", ")}</p>
                  ) : null}
                </Col>
                {isDashboard ? (
                  <Col style={{ maxWidth: "200px" }}>
                    <Link to={`/edit/${this.props.film._id}${this.props.location.search}`}>
                      <button>Edit</button>
                    </Link>
                    <br />
                    <button
                      style={{ backgroundColor: "#ba0707", color: "#fff" }}
                      onClick={this.toggle}
                    >
                      Delete
                    </button>
                  </Col>
                ) : null}
                {isProfile ? (
                  <Col style={{ maxWidth: "200px" }}>
                    <Link to={`/update/${this.props.film._id}`}>
                      <button>Edit</button>
                    </Link>
                  </Col>
                ) : null}
              </Row>
            </td>
          </tr>
        ) : null}
        <Modal
          show={confirmDelete}
          centered
          style={{ color: "black" }}
          onHide={this.toggle}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete {film.title}?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this film from the database?
          </Modal.Body>
          <Modal.Footer className="modal-buttons d-flex justify-content-between">
            <button onClick={this.toggle}>No, take me back!</button>
            <button
              style={{ backgroundColor: "#ba0707" }}
              onClick={this.onDelete}
            >
              Yes, delete
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { deleteFilm })(withRouter(FilmRow));
