import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getUserProfile,
  logoutUser,
  updateProfile,
} from "../../actions/authActions";
import moment from "moment";
import FilmTable from "../table/FilmTable";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/esm/Container";

import "./Profile.css";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingName: false,
      isEditingEmail: false,
      newName: props.auth.user.name,
      newEmail: props.auth.user.email,
    };

    this.props.getUserProfile(this.props.auth.user.id);
  }

  componentDidUpdate(prevProps) {
    const { auth } = this.props;
    const { isEditingEmail, isEditingName } = this.state;

    if (prevProps.auth.user.email !== auth.user.email && !isEditingEmail) {
      this.setState({ newEmail: auth.user.email });
    }

    if (prevProps.auth.user.name !== auth.user.name && !isEditingName) {
      this.setState({ newName: auth.user.name });
    }
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  onEditNameClick = () => {
    this.setState({ isEditingName: true });
  };

  onEditEmailClick = () => {
    this.setState({ isEditingEmail: true });
  };

  onCancelEditName = () => {
    this.setState({
      isEditingName: false,
      newName: this.props.auth.user.name,
    });
  };

  onCancelEditEmail = () => {
    this.setState({
      isEditingEmail: false,
      newEmail: this.props.auth.user.email,
    });
  };

  onSaveName = () => {
    const { newName } = this.state;
    this.props.updateProfile(this.props.auth.user.id, { name: newName });
    this.setState({ isEditingName: false });
  };

  onSaveEmail = () => {
    const { newEmail } = this.state;
    this.props.updateProfile(this.props.auth.user.id, { email: newEmail });
    this.setState({ isEditingEmail: false });
  };

  onChangeName = (e) => {
    this.setState({ newName: e.target.value });
  };

  onChangeEmail = (e) => {
    this.setState({ newEmail: e.target.value });
  };

  render() {
    const { isEditingName, isEditingEmail, newName, newEmail } = this.state;
    const { films } = this.props.auth;

    return (
      <Container className="middle-body static profile">
        <h1>Profile</h1>
        <Row style={{ maxWidth: "500px" }}>
          <Col>
            {isEditingName ? (
              <Form.Control
                type="text"
                value={newName}
                onChange={this.onChangeName}
              />
            ) : (
              <p>{this.props.auth.user.name}</p>
            )}
          </Col>
          <Col>
            {isEditingName ? (
              <>
                <button onClick={this.onSaveName}>Save</button>
                <button onClick={this.onCancelEditName}>Cancel</button>
              </>
            ) : (
              <button onClick={this.onEditNameClick}>Edit name</button>
            )}
          </Col>
        </Row>
        <Row style={{ maxWidth: "500px" }}>
          <Col>
            {isEditingEmail ? (
              <Form.Control
                type="email"
                value={newEmail}
                onChange={this.onChangeEmail}
              />
            ) : (
              <p>{this.props.auth.user.email}</p>
            )}
          </Col>
          <Col>
            {isEditingEmail ? (
              <>
                <button onClick={this.onSaveEmail}>Save</button>
                <button onClick={this.onCancelEditEmail}>Cancel</button>
              </>
            ) : (
              <button onClick={this.onEditEmailClick}>Edit email</button>
            )}
          </Col>
        </Row>
        <p>
          Sidecard member since {moment(this.props.auth.user.date).format("LL")}
        </p>
        <button style={{ marginBottom: "20px" }} onClick={this.onLogoutClick}>
          Logout
        </button>
        <div className="desktop-only">
          <h3>Your uploads:</h3>
          {films[0] ? (
            <FilmTable data={films} isProfile={true} onSelect={this.onSelect} />
          ) : (
            <p style={{ marginLeft: "20px" }}>No results found.</p>
          )}
        </div>
      </Container>
    );
  }
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getUserProfile,
  logoutUser,
  updateProfile,
})(Profile);
