import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { FaUserCircle } from "react-icons/fa";

import "./Layout.css";

class Navigation extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="desktop navbar d-flex" style={{ backgroundColor: "#ffc845" }}>
          <Container className="desktop">
            <Navbar.Brand
              as={Link}
              to="/"
              className="d-flex flex-column align-items-center"
            >
              <img src={logo} alt="sidecard" />
              <h1
                style={{
                  fontSize: "0.9em",
                  paddingTop: "10px",
                  color: "#003b49",
                  fontFamily: "futura-pt-bold",
                }}
              >
                [film access materials database]
              </h1>
            </Navbar.Brand>
            <Nav className="justify-content-center flex-nowrap button-yellow align-items-end mb-0">
              <Nav.Link as={Link} to="/about">
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/guides">
                How-To Guides
              </Nav.Link>
              <Nav.Link as={Link} to="/faq">
                FAQ / Glossary
              </Nav.Link>
            </Nav>
            {this.props.auth.isAuthenticated ? (
              <Nav className="justify-content-end align-items-end mb-0 mt-auto">
                {this.props.auth.user.isAdmin ? (
                  <React.Fragment>
                    <Nav.Link as={Link} to="/dashboard">
                      Dashboard
                    </Nav.Link>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Nav.Link as={Link} to="/add">
                      Add Film(s)
                    </Nav.Link>
                  </React.Fragment>
                )}
                <Nav.Link as={Link} to="/account">
                  <FaUserCircle size="1.3em" style={{ marginRight: "5px" }} />
                  {this.props.auth.user.name.split(" ")[0]}
                </Nav.Link>
              </Nav>
            ) : (
              <Nav className="justify-content-end align-items-end mb-0 mt-auto">
                <Nav.Link as={Link} to="/login">
                  Sign in
                </Nav.Link>
                <Nav.Link as={Link} to="/register">
                  Sign up
                </Nav.Link>
              </Nav>
            )}
          </Container>
        </div>
        <div className="navbar d-flex mobile" style={{ backgroundColor: "#ffc845", minHeight: "120px" }}>
          <Container className="mobile d-flex align-items-center flex-column">
            <Navbar.Brand as={Link} to="/">
              <img src={logo} alt="sidecard" />
              <h1
                style={{
                  fontSize: "1.05em",
                  paddingTop: "10px",
                  color: "#003b49",
                }}
              >
                [film access materials database]
              </h1>
            </Navbar.Brand>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Navigation.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Navigation);
