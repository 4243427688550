import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import "./Static.css";

export default class Contact extends Component {
  render() {
    return (
      <div className="contact middle-body">
        <h1>Contact</h1>
        <br />
        <h6>General Enquiries</h6>
        <p>
          {" "}
          <Link
            to="#"
            onClick={() => window.open("mailto:hello@sidecard.co.uk")}
            target="_blank"
            rel="noopener noreferrer"
          >
            hello@sidecard.co.uk
          </Link>
        </p>
        <br />
        <h6>Project Manager</h6>
        <p>
          {" "}
          <Link
            to="#"
            onClick={() => window.open("mailto:megan@filmhubwales.org")}
            target="_blank"
            rel="noopener noreferrer"
          >
            megan@filmhubwales.org
          </Link>
        </p>
        <br />
        <p>Inclusive Cinema</p>
        <p>Film Audience Network</p>
        <p>Diversity & Inclusion</p>
        <p>Film Hub Wales</p>
        <p>Chapter Arts</p>
        <p>Market Road</p>
        <p>Canton</p>
        <p>Cardiff</p>
        <p>CF5 1QE</p>
        <br />
        <h4>
          Follow us on:{" "}
          <a
            href="https://www.twitter.com/cinesidecard/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingRight: "5px" }}
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.facebook.com/cinesidecard/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingRight: "5px" }}
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/cinesidecard/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingRight: "5px" }}
          >
            <FaInstagram />
          </a>
        </h4>
      </div>
    );
  }
}
