import React, { useRef } from "react";
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
let _ = require("underscore");

export default function RangeSlider(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffc845",
      },
    },
  });

  const [value, setValue] = React.useState([props.min, props.max]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleChange2Debounced.current(newValue)
  };

  const handleChange2 = (newValue) => {
    props.onFilter(props.key_val + "[gte]", newValue[0], false, true);
    props.onFilter(props.key_val + "[lte]", newValue[1], false, true);
  };

  const handleChange2Debounced = useRef(_.debounce(handleChange2, 500));


  return (
    <ThemeProvider theme={theme}>
      <Slider
        getAriaLabel={() => "Minimum distance"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="off"
        min={props.min}
        max={props.max}
        color="primary"
        disableSwap
        style={{ margin: "0 20px", width: "82%" }}
      />
      <div
        className="d-flex justify-content-between"
        style={{ marginTop: "-18px" }}
      >
        <p style={{ marginLeft: "8px" }}>{value[0]}</p>
        <p>{value[1]}</p>
      </div>
    </ThemeProvider>
  );
}
