import "bootstrap/dist/css/bootstrap.min.css";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { logoutUser, setCurrentUser } from "./actions/authActions";
import "./App.css";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import EmailSuccess from "./components/auth/EmailSuccess";
import Dashboard from "./components/dashboard/Dashboard";
import EditFilm from "./components/dashboard/EditFilm";

import Home from "./components/home/Home";
import AddFilm from "./components/add/AddFilm";
import Success from "./components/add/Success";
import SuccessBulk from "./components/add/SuccessBulk";
import HowTo from "./components/static/HowTo";
import FAQ from "./components/static/FAQ";
import Contact from "./components/static/Contact";
import Accessibility from "./components/static/Accessibility";
import Privacy from "./components/static/Privacy";
import Terms from "./components/static/Terms";
import About from "./components/static/About";

import Navigation from "./components/layout/Navigation";
import Footer from "./components/layout/Footer";

import PrivateRoute from "./components/private-route/PrivateRoute";
import ModeratorRoute from "./components/private-route/ModeratorRoute";

import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import Users from "./components/dashboard/Users";
import Profile from "./components/user/Profile";
import EditFilmByUser from "./components/user/EditFilmByUser";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";
  }
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navigation />
            <Route exact path="/" component={Home} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/guides" component={HowTo} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/accessibility" component={Accessibility} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/terms-of-use" component={Terms} />
            <Route exact path="/about" component={About} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/sent" component={EmailSuccess} />
            <Route exact path="/reset/:token" component={ResetPassword} />

            <Switch>
              <ModeratorRoute exact path="/dashboard" component={Dashboard} />
              <ModeratorRoute exact path="/manage-users" component={Users} />
              <ModeratorRoute exact path="/edit/:id" component={EditFilm} />
              <PrivateRoute exact path="/add" component={AddFilm} />
              <PrivateRoute exact path="/success" component={Success} />
              <PrivateRoute exact path="/thanks" component={SuccessBulk} />
              <PrivateRoute exact path="/account" component={Profile} />
              <PrivateRoute exact path="/update/:id" component={EditFilmByUser} />

            </Switch>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
