import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";

import Collections from "../collections/Collections.js";
import "./Home.css";

class Home extends Component {
  render() {
    return (
      <div className="home">
        <Collections />
        <div className="mobile">
          {this.props.auth.isAuthenticated ? (
            <div className="d-flex flex-column">
              <Link to="/about">About</Link>
              <Link to="/guides">How-To Guides</Link>
              <Link to="/faq">FAQ / Glossary</Link>
              <Link to="/add">Add Film(s)</Link>
              {this.props.auth.user.isAdmin ? (
                <Link to="/dashboard">Dashboard</Link>
              ) : null}

              <Link to="/account">
                <FaUserCircle size="1.3em" style={{ marginRight: "5px" }} />
                {this.props.auth.user.name.split(" ")[0]}
              </Link>
              <br />
            </div>
          ) : (
            <div className="d-flex flex-column">
              <Link to="/about">About</Link>
              <Link to="/guides">How-To Guides</Link>
              <Link to="/faq">FAQ / Glossary</Link>
              <Link to="/login">Sign in</Link>
              <Link to="/register">Sign up</Link>
              <br />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
