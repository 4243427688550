import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class SuccessBulk extends Component {
  render() {
    return (
      <div className="middle-body">
        <h1>Success! Thanks for your submission!</h1>
        <p>Your films have been submitted for moderation.</p>
        <p>
          We aim for all films to be reviewed and published with 48 hours of
          submission.
        </p>
        <p>
          <em>
            If you have any questions, please contact{" "}
            <Link
              to="#"
              onClick={() => window.open("mailto:hello@sidecard.co.uk")}
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@sidecard.co.uk
            </Link>
            .
          </em>
        </p>
        <Link to="/">Back to film listings</Link>
      </div>
    );
  }
}
