import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Static.css";

export default class Privacy extends Component {
  render() {
    return (
      <div className="middle-body static">
        <h1>Privacy Policy</h1>
        <p>
          <strong>How do we collect information?</strong>
        </p>
        <p>
          We collect information from you when you visit our website in the
          following ways:
        </p>
        <ul>
          <li>
            <p>When you register as a user of Sidecard</p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              When you sign up to our mailing list through a web form. We
              use&nbsp;
              <a href="https://mailchimp.com/" target="_blank" rel="noopener noreferrer">
                Mailchimp
              </a>{" "}
              to manage our mailing list subscribers and to send updates.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              As cookies &ndash; small files that a site or its service provider
              transfers to your computer&rsquo;s hard drive through your web
              browser that (if you allow) enables the site&rsquo;s or service
              provider&rsquo;s systems to recognise your browser and capture and
              remember certain information. You can find more info on how to
              control cookies on your computer at&nbsp;
              <a href="https://www.aboutcookies.org/" target="_blank" rel="noopener noreferrer">
                aboutcookies.org
              </a>
              .
            </p>
          </li>
        </ul>
        <p>
          <strong>What information do we collect?</strong>
        </p>
        <p>We collect:</p>
        <ul>
          <li>
            <p>
              Your email address, first name, surname and preferences when you
              sign up to our mailing list, and how you use our emails &ndash;
              for example, whether you open them and which links you click on;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Your IP address, and details of which version of web browser you
              used;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Account information. If you sign up as a user, you must provide an
              email, password and address. We will also store your account
              preferences and settings.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Information on how you use the site, using&nbsp;
              <a href="http://aboutcookies.org/" target="_blank" rel="noopener noreferrer">
                cookies&nbsp;
              </a>
              and page tagging techniques.
            </p>
          </li>
        </ul>
        <p>
          <strong>Keeping your data secure</strong>
        </p>
        <p>
          Sending information over the internet is generally not completely
          secure, and we can&rsquo;t guarantee the security of your data while
          it&rsquo;s in transit.
        </p>
        <p>Any data you send is at your own risk.</p>
        <p>
          We have procedures and security features in place to keep your data
          secure once we receive it, in accordance with the Data Protection Act
          1998.
        </p>
        <p>
          Under the GDPR there are six lawful bases under which organisations
          can collect, use and store personal data. We have identified four
          which we rely upon for our organisational activities: Consent,
          Contractual, Legitimate Interests and Legal Obligation.
        </p>
        <p>
          <strong>Contractual:</strong> in many circumstances we rely on the
          lawful basis of &ldquo;performance of a contract&rdquo;, this enables
          us to process your personal data to provide our services (or in
          preparation for the contract).
        </p>
        <p>
          <strong>Consent:</strong> in some circumstances we rely on your
          specific consent, whereby you actively agree and &ldquo;opt-in&rdquo;
          (e.g. mailing list sign-ups). We will always make it clear how you can
          withdraw your consent at any time.
        </p>
        <p>
          <strong>Legal Obligation:</strong> there will be circumstances under
          which we are legally obliged to hold your personal data or required to
          disclose it to a third party by law, such as funders.
        </p>
        <p>
          <strong>Legitimate Interests:</strong> for some of our activities we
          rely on our legitimate business interests to collect and use your
          personal data. In such cases, we have balanced our interests with
          yours and do not believe these activities will have an overriding
          negative impact on your privacy rights and freedoms.
        </p>
        <p>
          <strong>Why and how we use your personal data</strong>
        </p>
        <p>Your personal data may be processed in any of the following ways:</p>
        <ul>
          <li>
            <p>
              Information that you provide by filling in forms on any of our
              sites or sub domains; this includes information provided at the
              time of registering to use our site, subscribing to our Services,
              posting material, any inquiry through the &ldquo;Contact&rdquo;
              section of our site or requesting further Services;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>Establish and manage user accounts;</p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Communicate about, and administer participation, in special
              events, surveys, training, online, and any other offers;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Comply with and enforce applicable legal requirements, agreements,
              and policies; and;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>Any other activity consistent with this Privacy Notice.</p>
          </li>
        </ul>
        <p>
          <strong>Disclosing your information</strong>
        </p>
        <p>
          We may pass on your personal information if we have a legal obligation
          to do so, or if we have to enforce or apply our{" "}
          <Link to="/terms-of-use">terms of use</Link> and other agreements.
          This includes exchanging information with other organisations for
          legal reasons.
        </p>
        <p>
          We may also pass on your personal information if we are obliged to do
          so by one of our funders.
        </p>
        <p>
          We won&rsquo;t share your information with any other organisations for
          marketing, market research or commercial purposes, and we don&rsquo;t
          pass on your details to other websites.
        </p>
        <p>
          <strong>Your rights</strong>
        </p>
        <p>
          If you&rsquo;ve signed up for email alerts, you can unsubscribe or
          change your settings at any time.
        </p>
        <p>
          If you would prefer us not to contact you (via phone, email or any
          other way), nor use your data in the way described in this Privacy
          Notice or want us to pass your details on to third parties, please do
          not hesitate to contact us at the following email address:{" "}
          <Link
            to="#"
            onClick={() => window.open("mailto:hello@sidecard.co.uk")}
            target="_blank" rel="noopener noreferrer"
          >
            hello@sidecard.co.uk
          </Link>
        </p>
        <p>
          <strong>Links to other websites</strong>
        </p>
        <p>The Sidecard website contains links to other websites.</p>
        <p>
          This privacy policy only applies to the Sidecard website, and
          doesn&rsquo;t cover other services and transactions that we link to.
          These services have their own terms and conditions and privacy
          policies.
        </p>
        <p>
          <strong>Following a link to another website</strong>
        </p>
        <p>
          If you go to another website from this one, read the privacy policy on
          that website to find out what it does with your information.
        </p>
        <p>
          <strong>
            Following a link to the Sidecard website from another website
          </strong>
        </p>
        <p>
          If you come to the Sidecard website from another website, we may
          receive information from the other website. We don&rsquo;t use this
          data. You should read the privacy policy of the website you came from
          to find out more about this.
        </p>
      </div>
    );
  }
}
