import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Success extends Component {
  render() {
    const { auth } = this.props;

    return (
      <div className="middle-body">
        <h1>Success! Thanks for your submission!</h1>
        {auth.user.isTrusted ? (
          <p>Your film(s) have been submitted and published.</p>
        ) : (
          <React.Fragment>
            <p>Your film(s) have been submitted for moderation.</p>
            <p>
              We aim for all films to be reviewed and published with 48 hours of
              submission.
            </p>
          </React.Fragment>
        )}
        <p>
          <em>
            If you have any questions, please contact{" "}
            <Link
              to="#"
              onClick={() => window.open("mailto:hello@sidecard.co.uk")}
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@sidecard.co.uk
            </Link>
            .
          </em>
        </p>
        <Link to="/">Back to film listings</Link>
      </div>
    );
  }
}

Success.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Success);
