import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";

import "./Auth.css";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
    };

    this.props.registerUser(newUser, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <Container className="middle-body auth">
        <h1>Sign Up</h1>
        <p>
          Already have an account? <Link to="/login">Log in</Link>
        </p>
        <p>
          By clicking sign up you are indicating that you have read and agree to
          the <Link to="/terms-of-use">Terms of Use</Link> and{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </p>
        <form noValidate onSubmit={this.onSubmit} style={{ maxWidth: "500px" }}>
          <div>
            <span className="red-text">{errors.name}</span>
            <Form.Control
              onChange={this.onChange}
              value={this.state.name}
              error={errors.name}
              id="name"
              type="text"
              placeholder="Name"
              className={classnames("input-field", {
                invalid: errors.name,
              })}
            />
            <span className="red-text">{errors.email}</span>
            <Form.Control
              onChange={this.onChange}
              value={this.state.email}
              error={errors.email}
              id="email"
              type="email"
              placeholder="Email"
              className={classnames("input-field", {
                invalid: errors.email,
              })}
            />
            <span className="red-text">{errors.password}</span>

            <Form.Control
              onChange={this.onChange}
              value={this.state.password}
              error={errors.password}
              id="password"
              type="password"
              placeholder="Password"
              className={classnames("input-field", {
                invalid: errors.password,
              })}
            />
            <span className="red-text">{errors.password2}</span>

            <Form.Control
              onChange={this.onChange}
              value={this.state.password2}
              error={errors.password2}
              id="password2"
              type="password"
              placeholder="Confirm Password"
              className={classnames("input-field", {
                invalid: errors.password2,
              })}
            />
            <button
              style={{
                width: "100%",
              }}
              type="submit"
              className="btn btn-large waves-effect waves-light hoverable blue accent-3"
            >
              Sign up
            </button>
          </div>
        </form>
      </Container>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
