import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING, GET_USERS } from "./types";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/register", userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage

      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Action to update the user profile
export const updateProfile = (id, profileData) => (dispatch) => {
  axios
    .patch(`/api/users/${id}`, profileData)
    .then((res) =>
      dispatch({
        type: 'UPDATE_USER',
        payload: res.data.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Send password reset email
export const sendPasswordEmail = (email, history) => (dispatch) => {
  axios
    .get(`/api/users/password/${email}`)
    .then((res) => history.push("/sent"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Reset password
export const resetPassword = (data, history) => (dispatch) => {
  axios
    .post("/api/users/password-reset", data)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// Get all users
export const getUsers = () => (dispatch) => {
  axios
    .get("/api/users/")
    .then((res) =>
      dispatch({
        type: GET_USERS,
        payload: res.data.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_USERS,
        payload: null,
      })
    );
};

// Update user
export const editUser = (id, userData) => (dispatch) => {
  axios
    .patch(`/api/users/${id}`, userData)
    .then((res) =>
      axios
        .get("/api/users/")
        .then((res) =>
          dispatch({
            type: GET_USERS,
            payload: res.data.data,
          })
        )
        .catch((err) =>
          dispatch({
            type: GET_USERS,
            payload: null,
          })
        )
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const getUserProfile = (userId) => async (dispatch) => {
  try {
    dispatch({ type: "USER_PROFILE_REQUEST" });

    const { data } = await axios.get(`/api/users/profile/${userId}`);

    dispatch({
      type: "USER_PROFILE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "USER_PROFILE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
