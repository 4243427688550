import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.user.isAdmin) {
      this.props.history.push("/dashboard");
    } else if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.user.isAdmin) {
      this.props.history.push("/dashboard");
    } else if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/");
    } else if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(userData);
  };

  render() {
    const { errors } = this.state;

    return (
      <Container className="middle-body auth">
        <h1>Sign In</h1>
        <br />
        <p className="grey-text text-darken-1">
          Don't have an account? <Link to="/register">Register</Link>
        </p>
        <p className="grey-text text-darken-1">
          Forgot your password? <Link to="/forgot-password">Reset password</Link>
        </p>
        <form noValidate onSubmit={this.onSubmit} style={{ maxWidth: "500px" }}>
          <div>
            <span className="red-text">
              {errors.email}
              {errors.emailnotfound}
            </span>
            <Form.Control
              onChange={this.onChange}
              value={this.state.email}
              error={errors.email}
              id="email"
              type="email"
              placeholder="Email"
              className={classnames("input-field", {
                invalid: errors.email || errors.emailnotfound,
              })}
            />
            <span className="red-text">
              {errors.password}
              {errors.passwordincorrect}
            </span>
            <Form.Control
              onChange={this.onChange}
              value={this.state.password}
              error={errors.password}
              id="password"
              type="password"
              placeholder="Password"
              className={classnames("input-field", {
                invalid: errors.password || errors.passwordincorrect,
              })}
            />
            <button
              style={{
                width: "100%"
              }}
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
      </Container>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
