import React, { Component } from "react";
import ReactLoading from "react-loading";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

export default class EmptySidebar extends Component {
  render() {
    return (
      <div style={{ minHeight: "100%" }}>
        <ProSidebar>
          <Menu style={{ margin: "0.5rem" }}>
            <MenuItem>
              <h4 style={{ fontFamily: "futura-pt-bold" }}>Filters</h4>
            </MenuItem>
            <div style={{ margin: "20px" }}>
            <ReactLoading  type='bubbles' color="#fff"  />
            </div>
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}
