import React, { Component } from "react";
import classnames from "classnames";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sendPasswordEmail } from "../../actions/authActions";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.user.isAdmin) {
      this.props.history.push("/dashboard");
    } else if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.user.isAdmin) {
      this.props.history.push("/dashboard");
    } else if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/");
    } else if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.sendPasswordEmail(this.state.email, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <Container className="middle-body auth">
        <h1>Reset Your Password</h1>
        <br />
        <p>
          Type your email below to receive an email with reset instructions.
        </p>
        <form noValidate onSubmit={this.onSubmit} style={{ maxWidth: "500px" }}>
          <div>
            <span className="red-text">
              {errors.email}
            </span>
            <Form.Control
              onChange={this.onChange}
              value={this.state.email}
              error={errors.email}
              id="email"
              type="email"
              placeholder="Email"
              className={classnames("input-field", {
                invalid: errors.email || errors.emailnotfound,
              })}
            />
            <button
              style={{
                width: "100%",
                borderRadius: "3px",
              }}
              type="submit"
            >
              Send reset email
            </button>
          </div>
        </form>
      </Container>
    );
  }
}

ForgotPassword.propTypes = {
  passwordEmail: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { sendPasswordEmail })(ForgotPassword);
