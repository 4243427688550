import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUsers, editUser } from "../../actions/authActions";
import Sidebar from "./Sidebar";
import Container from "react-bootstrap/esm/Container";
import Table from "react-bootstrap/Table";
import Switch from "react-switch";

import "./Dashboard.css";

class Users extends Component {
  constructor() {
    super();

    this.handleTrustedChange = this.handleTrustedChange.bind(this);
    this.handleAdminChange = this.handleAdminChange.bind(this);
  }

  componentDidMount() {
    this.props.getUsers();
  }

  handleTrustedChange(id, checked) {
    const params = {isTrusted: !checked}
    this.props.editUser(id, params)
  }

  handleAdminChange(id, checked) {
    const params = {isAdmin: !checked}
    this.props.editUser(id, params)
  }

  renderRows() {
    return this.props.auth.users.map((user) => (
      <tr key={user._id}>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>
          <Switch
            onChange={() => this.handleTrustedChange(user._id, user.isTrusted)}
            checked={user.isTrusted}
          />
        </td>
        <td>
          <Switch
            onChange={() => this.handleAdminChange(user._id, user.isAdmin)}
            checked={user.isAdmin}
          />
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <div className="dashboard">
        <Sidebar />
        <div>
          <Container>
            <h4 style={{ margin: "50px 0 20px 0" }}>Manage Users</h4>
            <p>Below you are able to update the status of users to:</p>
            <ul>
              <li>
                Super-user: Able to add films individually or via bulk upload
                directly to the site, without the need for moderation.
              </li>
              <li>
                Admin: Able to edit films, approve films and manage users.
              </li>
            </ul>
            <Table
              responsive
              striped
              hover
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Super User?</th>
                  <th>Admin?</th>
                </tr>
              </thead>
              <tbody>{this.renderRows()}</tbody>
            </Table>
            <br />
          </Container>
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { editUser, getUsers })(Users);
