import { combineReducers } from "redux";
import authReducer from "./authReducer";
import filmReducer from "./filmReducer";
import errorReducer from "./errorReducer";

export default combineReducers({
  auth: authReducer,
  film: filmReducer,
  errors: errorReducer
});
